import React, { Fragment, useState, useEffect } from "react";
import Breadcrumb from "../../layout/breadcrumb";
import { Container, Row, Col, Card, CardHeader, Table, Label, CardBody, Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import axios from "axios";
import 'devextreme/dist/css/dx.material.teal.light.css';
import PopupCatalogF from "./popupCatalogF";
import DetailTemplateFCat2 from './DetailTemplateFCat2';

import DataGrid, { Column, Editing, Popup, Paging, Lookup, Form, SearchPanel, Scrolling, Pager, Export, HeaderFilter, RequiredRule, MasterDetail } from 'devextreme-react/data-grid';
import 'devextreme-react/text-area';
import { useTranslation } from 'react-i18next';
import { SelectBox } from 'devextreme-react/select-box';


const CatalogList = () => {

  // Get the information of user

  const infoUserLogin = JSON.parse(localStorage.getItem('infoUserLogin'));

  // To traslate the words
  const { t } = useTranslation();

  // Spaces
  const tab = '\u00A0';

  // IdChurch of user logged
  const [idChurchUserLogged, setIdChurchUserLogged] = useState('');

  // To get the information of the first catalog
  const [dataCatalogFirst, setDataCatalogFirst] = useState([]);

  const [readOnlyForm, setReadOnlyForm] = useState(false);

  // To get the information of the menu to edit
  const [dataChurches, setDataChurches] = useState([]);


  // To get the list information of the menu
  const [catalogListFirst, setCatalogListFirst] = useState([]);

  // To get the list of status
  const [listStatus, setListStatus] = useState([]);

  // To determinate if the event is create or edit:  edit:true and create:false
  const [isEdit, setIsEdit] = useState(false);

  const [controlModal, setControlModal] = useState(false);

  // To get the status of Menu
  const [statusCatalogF, setStatusCatalogF] = useState('');

  // To save the Id of the church to which the role belongs 
  const [idChurch, setIdChurch] = useState('');

  // To get the information of the accounts types
  const [dataAccountTypes, setDataAccountTypes] = useState([]);

  // IdChurch of user logged
  const [idAccountType, setIdAccountType] = useState('');

  const [permissionsModule, setPermissionsModule] = useState([]);

  // Id Classification account CDSP, DSP
  const [idClassification, setIdClassification] = useState('');

  // Is editable catalog account
  const [isEditableA, setIsEditableA] = useState(2);

  // Array  classification
  var classificationAccount = [{
    'id': 1,
    'descrip': "CDSP",
  }, {
    'id': 2,
    'descrip': "CSP",
  },
  {
    'id': 3,
    'descrip': "Ninguno",
  },
  ];

  const arrayIsEditableA = [
    { id: 1, text: t('positiveAnswer') },
    { id: 2, text: t('negativeAnswer') },
  ];

  const [dataClassification, setDataClassification] = useState(classificationAccount);


  // Use effect is launch one time when the page load
  useEffect(() => {


    // Get list of church 
    axios
      .get(`${process.env.REACT_APP_DOMAIN_SERVER}api/churches`)
      .then((response) => {
        setDataChurches(response.data.churches);
      })
      .catch((error) => {
        console.log(error);
      });

    // Get the account types 
    axios
      .get(`${process.env.REACT_APP_DOMAIN_SERVER}api/getAcTypes`)
      .then((response) => {
        setDataAccountTypes(response.data.accountsType);
      })
      .catch((error) => {
        console.log(error);
      });


    if (infoUserLogin !== null && infoUserLogin !== undefined) {

      getCatalogList();

      // Get the information of the church of the user belongs
      axios
        .get(`${process.env.REACT_APP_DOMAIN_SERVER}api/getInforChurch/${infoUserLogin.idRol}`)
        .then((response) => {
          setIdChurchUserLogged(response.data.infoChurch[0].IdChurch);
        })
        .catch((error) => {
          console.log(error);
        });
      // To determinate if the user logged is Admin
      setReadOnlyForm(infoUserLogin.idRol == 1 ? false : true);
    }

  }, []);

  // Get the list of status only load once time
  useEffect(() => {
    // We pass like parameter 1 because 1 has the general status
    axios
      .get(`${process.env.REACT_APP_DOMAIN_SERVER}api/processState/${1}`)
      .then((response) => {
        setListStatus(response.data.listStatus);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);


  useEffect(() => {
    // Get general permissions

    if (infoUserLogin !== null) {
      if (infoUserLogin.id !== null && infoUserLogin.id !== '') {
        axios
          .get(`${process.env.REACT_APP_DOMAIN_SERVER}/api/getPermission/${infoUserLogin.idRol}`)
          .then((result) => {
            setPermissionsModule(result.data.response);
          })
          .catch((error) => {
            console.log(error);
          });

      } else {
        setPermissionsModule([]);
      }
    } else {
      setPermissionsModule([]);

    }

  }, []);

  // Function to allow to get catalog first
  function getCatalogList() {
    axios
      .get(`${process.env.REACT_APP_DOMAIN_SERVER}api/getCatalogsByIdR/${infoUserLogin.idRol}`)
      .then((response) => {
        setCatalogListFirst(response.data.catalogs);
        console.log(response.data.catalogs);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  // To create edit icon 
  const cellRenderAction = (data) => {
    return <div align="center"><i style={{ cursor: 'pointer' }} className="icofont icofont-ui-edit" onClick={() => editCatalog(data)} /></div>;
  }

  const createAccount = (e) => {
    setDataCatalogFirst({
      accountNumber: "",
      accountName: "",
    });
    setIsEdit(false);
    setIdChurch(idChurchUserLogged);
    setIdAccountType('');
    setControlModal(!controlModal);
    setIdClassification(1);
    setIsEditableA(2);
  };

  const editCatalog = (e) => {
    setIsEdit(true);
    setDataCatalogFirst(e.data);
    setControlModal(!controlModal);
    setStatusCatalogF(e.data.statusAccount);
    setIdChurch(e.data.IdChurch);
    setIdAccountType(e.data.IdAccountType);
    setIdClassification(e.data.IdClassification != null ? e.data.IdClassification : '');
    setIsEditableA(e.data.isEditable != null ? e.data.isEditable : 2);

  }


  const handleChangeChurch = (e) => {
    if (e.value !== null) {
      axios
        .get(`${process.env.REACT_APP_DOMAIN_SERVER}api/getCatalogsByIdChurch/${e.value.IdChurch}`)
        .then((response) => {
          setCatalogListFirst(response.data.catalogs);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      setCatalogListFirst([]);
    }
  }

  return (
    <Fragment>
      <Breadcrumb parent="Catalog" title={t("catalogTitle")} />
      <Container fluid={true}>
        <Row className="justify-content-md-center">
          <Col sm="12">
            {
              permissionsModule.find(v => { return v.functionName === 'filterChurchAdmin' }) ?
                (
                  <Col md="6 mb-2" >
                    <Label className="nameFunction" style={{ fontSize: '20px' }}>{t("selectChurch")}:</Label>
                    <SelectBox
                      dataSource={dataChurches}
                      displayExpr="churchName"
                      onValueChanged={handleChangeChurch}
                      searchEnabled={true}
                      className={'form-control dxSelectBorder'}
                      placeholder={t('selectChurch')}
                      showClearButton={true}
                      name="selectChurch"
                    />
                    <br /><br /><br />
                  </Col>
                ) :
                ''
            }


            <Card>
              <CardBody>
                <Row >
                  <Col sm="12" lg="12" xl="12">
                    <div className="table-responsive">
                      <div id="data-grid-demo" className="table-primary">

                        {/* Popup */}
                        <PopupCatalogF
                          controlModal={controlModal} setControlModal={setControlModal}
                          dataCatalogFirst={dataCatalogFirst}
                          isEdit={isEdit}
                          listStatus={listStatus}
                          setCatalogListFirst={setCatalogListFirst}
                          statusCatalogF={statusCatalogF}
                          setStatusCatalogF={setStatusCatalogF}
                          setIdChurch={setIdChurch}
                          idChurch={idChurch}
                          dataChurches={dataChurches}
                          readOnlyForm={readOnlyForm}
                          dataAccountTypes={dataAccountTypes}
                          idAccountType={idAccountType}
                          setIdAccountType={setIdAccountType}
                          idClassification={idClassification}
                          setIdClassification={setIdClassification}
                          dataClassification={dataClassification}
                          isEditableA={isEditableA}
                          setIsEditableA={setIsEditableA}
                        />

                        <div className="btn-showcase ">
                          <Button className="btn-pill" color="primary" onClick={createAccount}><i className="icofont icofont-ui-add"></i>{tab + tab}{t('create')}</Button>
                        </div>

                        <DataGrid id="grid-container"
                          dataSource={catalogListFirst}
                          keyExpr="IdCatFirstLevel"
                          showBorders={true}
                          rowAlternationEnabled={true}
                          columnAutoWidth={true}
                          t={t}
                        >

                          <HeaderFilter visible={true} allowSearch={true} />
                          <Export enabled={true} />
                          <SearchPanel visible={true} highlightCaseSensitive={true} width={350} />
                          <Scrolling
                            useNative={false}
                            scrollByContent={true}
                            scrollByThumb={true}
                            showScrollbar="onHover" />
                          <Paging defaultPageSize={5} />
                          <Pager
                            showPageSizeSelector={true}
                          />

                          <Column caption={t('actions')} cellRender={cellRenderAction} width={100} />
                          {/* <Column dataField="churchName" caption={t('church')} ></Column> */}
                          <Column dataField="accountNumber" caption={t('accountNumber')} ></Column>
                          <Column dataField="accountName" caption={t('accountName')} >
                            <RequiredRule />
                          </Column>
                          <Column dataField="accountNameType" caption={t('accountType')} >
                            <RequiredRule />
                          </Column>
                          <Column dataField="IdClassification" caption={t('classificationAccount')} >
                            <Lookup dataSource={classificationAccount} valueExpr="id" displayExpr="descrip" />
                            <RequiredRule />
                          </Column>
                          <Column dataField="isEditable" caption={t('isEditableA')} >
                            <Lookup dataSource={arrayIsEditableA} valueExpr="id" displayExpr="text" />
                            <RequiredRule />
                          </Column>
                          <Column dataField="statusAccount" caption={t("selectStatus")} >
                            <Lookup dataSource={listStatus} valueExpr="id" displayExpr="name" />
                          </Column>
                          <MasterDetail
                            enabled={true}
                            component={DetailTemplateFCat2}
                          />
                        </DataGrid>

                      </div>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );

};

export default CatalogList;
