import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        // we init with resources
        resources: {
            es: {
                translations: {
                    //HOME PAGE
                    aboutUs: "Un poco acerca de nosotros...",
                    textAboutUs: "Nace con la idea de servir a miles de personas que buscan un hermoso refrigerador a muy buen precio. Entendemos la realidad y por eso damos lo mejor de nosostros para satisfacer tus necesidades día, con día.",
                    mission: "Misión",
                    textMission: "Nuestra misión es ayudar a las personas a cuidar sus alimentos, proporcionándoles refrigeradores a un precio accesible.",
                    vision: "Visión",
                    textVision: "Ser una empresa líder en ayudar a las personas a cuidar sus alimentos mediante refrigeradores usados en muy buen estado. Convertirnos en una empresa altamente competitiva, confiable y responsable de los productos que manejamos.",
                    deliveryService: "Servicio de entrega",
                    textDeliveryService: "Trabajamos día con día para brindar el mejor servicio de entrega de refrigeradoras 😎,        Contamos con un equipo con mucha experiencia en entregas de refrigeradores hasta tu casita  y dejarlo debidamente conectado 🤩. Si no tienes carro para llevarte el refrigerador no te preocupes, nosotros somos tu mejor opción.",
                    repairService: "Servicio de reparación",
                    textRepairService: "¿No te funciona tu refrigerador?, ¿Se te arruina la comida?, ¿no congela?, ¿te preocupa cuidar tu comida? 🥺. Si te identificas con alguno de estos problemas y tu refrigerador es marca Kenmore  o LG, no te preocupes más nosotros te lo reparamos 🤩, tenemos técnicos especializados que te pueden ayudar asi que no lo dudes más, pon tu refrigerador en buenas mano.",
                    ourServices: "Nuestros servicios",
                    seeProducts: "Ver productos",
                    lookingFor: "¿Qué estás buscando?",
                    textSingIn: "Ingresa tu correo y contraseña para iniciar",
                    textUser: "Correo o usuario",
                    see: 'Ver',
                    information: "Información",
                    copyLink: "Copiar enlace",
                    deliveryWarning: "No se realizan entregas de refrigeradores que tienen distancia mayores a 50 millas",
                    btnManagmentImage: "Administrar imagenes",
                    myRequest: "Mis solicitudes",
                    requestStatus: "Estado de la solicitud",
                    beginingDate: "Fecha Incio",
                    endingDate: "Fecha fin",
                    errorDates: "Debe seleccionar fecha de inicio y fecha de fin",
                    seller: "Vendedor",
                    mySales: "Mis ventas",
                    listUsers: "Lista de usuarios",
                    titleAlertD: "¿Está seguro de eliminar esto?",
                    descripAlertD: "Una vez eliminado, ¡no será capaz de recuperar este archivo!",
                    descripAlertInvoice: "Una vez anulada, ¡no será posible recuperar esta información!",

                    msgDeleted: "Eliminado, correctamente",
                    msgRejectDeleted: "Su archivo está a salvo",
                    errorTask: "Algo salió mal!",
                    seeDetail: "Ver detalles",
                    cancelSale: "Anular la venta",
                    msgSale: "Venta anulada correctamente",
                    btnDownload: "Descargar imagenes",



                    //END HOME PAGE

                    //MENU
                    titleListFirstLevel: "Menú de primer nivel",
                    titleListSecondLevel: "Menú de segundo nivel",
                    titleListThirdLevel: "Menú de tercer nivel",
                    nameMenu: "Nombre menú",
                    menuFirstLevel: "Menú primer nivel",
                    menuSecondLevel: "Menú segundo nivel",
                    menuThirdLevel: "Menú tercer nivel",
                    iconName: "Icono",
                    pathMenu: "Url",
                    moduleName: "Nombre del módulo",
                    titleModules: "Módulos",
                    titleFunctions: "Funciones",
                    functionName: "Nombre de la función",
                    //END MENU

                    //General Text
                    General: "General",
                    Dashboards: "Cuadros de mando",
                    Widgets: " widgets",
                    Dashboard: "Tablero",
                    Default: "Defecto",
                    Ecommerce: "Comercio electrónico",
                    Chart: "Gráfico",
                    Applications: "Aplicaciones",
                    ReadytouseApps: "Aplicación lista para usar",
                    email: "Correo",
                    placeholderEmail: "Ingrese el correo",
                    cancel: "Cancelar",
                    create: "Crear",
                    update: "Actualizar",
                    delete: "Eliminar",
                    close: "Cerrar",
                    processing: "Procesando...",
                    show: "Mostrar",
                    phoneNumber: "Teléfono",
                    address: "Dirección",
                    placeHolderAddress: "Ingrese la dirección",
                    firstName: "Nombres",
                    lastName: "Apellidos",
                    placeHolderFirstName: "Nombres",
                    placeHolderLastName: "Apellidos",
                    placeHolderPhoneNumber: "Teléfono",
                    errorFirstName: "Debe ingresar el nombre",
                    errorLastName: "Debe ingresar los apellidos",
                    errorPhoneNumber: "Debe ingresar el número de teléfono",
                    errorEmail: "Debe ingresar un correo válido",
                    errorAddress: "Debe ingresar la dirección",
                    errorRequired: "Este campo no puede ser vacío",
                    SingIn: "Usted no tiene usuario logeado",
                    errorCreate: "Error al crear el registro",
                    successCreated: "Registro creado correctamente",
                    successUpdated: "Registro actualizado correctamente",
                    country: "País",
                    department: "Departmento",
                    createdDate: 'Fecha de creación',
                    actions: "Acciones",
                    editInfo: "Editar información",
                    placeHolderSelect: "Seleccione una opción",
                    selectStatus: "Estado",
                    errorStatus: "Debe seleccionar un estado",
                    description: "Descripción",
                    placeHolderDescription: "Ingrese la descripción",
                    newRecord: "Nuevo registro",
                    createInfo: "Ingresar Información",
                    placeHolderGeneralName: "Ingrese nombre",
                    document: "Documento",
                    placeHolderDocument: "Ingrese el número de documento",
                    date: 'Fecha',
                    search: 'Buscar',
                    pay: "Pagar",
                    price: "Precio",
                    code: "Código",
                    quantity: "Cantidad",
                    services: "Servicios",
                    home: "Inicio",
                    singInP: "Iniciar Sesión",
                    products: "Productos",
                    hide: "Ocultar",
                    loading: "Cargando...",
                    chargeDelivery: "Costo por entrega",

                    //employeesComponent
                    employee: "Empleado",
                    employees: "Empleados",
                    titleEmployeeCreate: "Agregar empleado",
                    subtitleEmployeeCreate: "Ingrese la información del empleado",
                    placeholderEmployees: "Seleccione un empleado",
                    errorEmployee: "Debe seleccionar un empleado",
                    positionCompany: "Cargo en la empresa",
                    selectCountry: "Seleccione país",
                    selectDepartment: "Seleccione departamento",
                    selectMunicipio: "Seleccione municipio",
                    dui: "Dui",
                    placeHolderDui: "Dui",
                    placeHolderPositionCompany: "Seleccione un cargo",
                    placeHolderCountry: "Seleccione un país",
                    placeHolderDepartment: "Seleccione un departamento",
                    placeHolderMunicipio: "Seleccione un municipio",
                    errorDui: "Debe ingresar el dui",
                    errorPositionCompany: "Debe selecionar un cargo",
                    errorCountry: "Debe selececionar un país",
                    titleListEmployee: "Lista de empleados",
                    subtitleListEmployee: "Información de los empleados",

                    //UsersComponents
                    user: "Usuario",
                    users: "Usuarios",
                    userName: "Nombre de usuario",
                    errorUserName: "Debe ingresar un nombre de usuario",
                    titleUserCreate: "Agregar usuario",
                    subtitleUserCreate: "Ingrese la información del usuario",
                    placeholderUserName: "Ingresa el nombre de usuario",
                    password: "Contraseña",
                    placeholderPassword: "Ingresa la contraseña",
                    errorPassword: 'Contraseña no válida',
                    passConfirm: "Confirmar contraseña",
                    headPopPass: "La contraseña debe contener",
                    bodyPopPass1: "1- Al menos 6 caracteres.",
                    bodyPopPass2: "2- Incluir al menos una mayúscula.",
                    bodyPopPass3: "3- Al menos un número.",
                    bodyPopPass4: "4- Al menos uno de los símbolos(.*@~#-+:;=^%/)",
                    placeholderPassConfirm: "Confirme la contraseña",
                    errorPassConfirm: "Debe confirmar la contraseña",
                    errorMatchPassword: "Las contraseñas no coinciden",
                    errorSingIn: "Oppss.. El usuario o la contraseña son incorrectass.",
                    titleListUsers: "Lista de usuarios",
                    subtitleListUsers: "Visualiza la información de los usuarios",
                    nameFirst: "Nombre",
                    nameLast: "Apellido",
                    usrName: "Usuario",
                    errorLoginSesion: "Vuelva a iniciar sesión, por favor",
                    rol: "Seleccione un rol",
                    errorRol: "Para asignar permisos, debe seleccionar un rol por favor",
                    errorDate: "Seleccione una fecha correcta",
                    //Category
                    categories: "Categorías",
                    category: "Categoría",
                    placeholderCategory: "Ingrese la categoría",
                    placeholderSelectCategory: "Seleccione una categoría",
                    titleListCategories: "Lista de categorías",

                    //subcategories
                    titleListSubcategories: "Lista de subcategorias",
                    subcategory: "Subcategoría",
                    errorSubCategoryName: "Debe ingresar el nombre de un modelo",

                    //Providers
                    parentProvider: "Proveedores",
                    titleListProviders: "Listado de proveedores",
                    provider: "Proveedor",
                    giro: "Giro",
                    placeHolderGiro: "Ingrese el rubro",
                    placeHolderProvider: "Ingrese el nombre del proveedor",
                    errorProvider: "Debe de seleccionar un proveedor",

                    // List brands
                    titleListBrands: "Lista de marcas",
                    subttleListBrands: "Visualiza la información de la marca",
                    nameBrand: "Marca",
                    descriptionBrand: "Descripción",
                    errorBrandName: "Debe ingresar el nombre de una marca",
                    errorDescription: "Debe ingresar la descripción ",

                    // Product
                    product: "Producto",
                    titleCreateProduct: "Agregar producto",
                    titleEditProduct: "Editar producto",

                    productName: "Nombre del producto",
                    productCode: "Código de producto",
                    stockLimit: "Límite de existencias",
                    stockProduct: "Existencias",
                    percentageProfit: "Porcentaje de ganancia",
                    barcode: "Código de barra",
                    amount: "Cantidad",
                    unitPrice: "Precio unitario de compra",
                    saleUnitPrice: "Precio de venta",
                    productPhotos: "Seleccionar fotos",
                    errorProductName: "Debe ingresar un nombre de producto",
                    errorCategory: "Debe seleccionar una categoría",
                    errorSubCategory: "Debe seleccionar una subcategoría",
                    errorStockLimit: "Debe ingresar un límite de existencias",
                    errorBarcode: "Debe ingresar un código de barra",
                    errorSelectBrand: "Debe seleccionar una marca",
                    errorAmount: "Debe ingresar una cantidad",
                    errorUnitPrice: "Debe ingresar un precio unitario",
                    errorUnitSalePrice: "Debe ingresar un precio de venta",
                    errorPercentageProfit: "Debe ingresar un porcentaje de ganancia",
                    generalInformation: "Orden de compra",
                    enterProduct: "Agregar existencias",
                    positiveAnswer: "Si",
                    negativeAnswer: "No",
                    questionRbtAddProduct: "¿Desea registrar existencias?",
                    choosePicture: "Seleccione imágenes",
                    parentProducts: "Productos",
                    titleListProducts: "Lista de productos",
                    image: "Imagen",
                    priority: "Prioridad",
                    visibleCustomer: "Visible para el cliente",
                    placeHolderSelectProdut: "Seleccione un producto",
                    errorSelectProduct: "Debe seleccionar un producto, por favor.",
                    btnSearch: "Buscar",
                    errorPriority: "Debe ingresar un númer válido",
                    msgExistImage: "No hay images para este producto",
                    addStockMsg: "Agregar existencias",
                    productDiscount: "Descuento del producto",
                    admin: "Administrar",
                    errorSelectImg: "Debe seleccionar almenos una imagen",
                    productDetail: "Detalle del producto",
                    errorPrice: "El precio no puede estar vacío y no puede ser menor que $100",


                    //sales
                    titleSales: "Lista de ventas",
                    titleSalesCreate: "Nueva venta",
                    sale: 'Venta',
                    sales: 'Ventas',
                    orderNumber: '#Orden',
                    total: 'Total',
                    subTotal: 'Subtotal',
                    discount: 'Descuento',
                    client: 'Cliente',
                    placeHolderClient: 'Seleccione un cliente',
                    errorEmptyClient: 'Debe seleccionar un cliente',
                    paymentType: 'Tipo de pago',
                    text: 'Texto',
                    searchProduct: 'Buscar productos',
                    searchTooltipSaleBar: 'Búsqueda por código de barra',
                    searchTooltipSaleText: 'Búsqueda por texto',
                    totalPay: "Total a pagar",
                    warningSaleQuantity: "Debe agregar al menos un producto",
                    invalidStock: "Existencias insuficientes",
                    detailSaleTitle: "Detalle de la orden de venta",

                    // Clientes
                    clients: "Clientes",
                    linkMeta: "Enlace de facebook",
                    placeHolderLinkMeta: "Enlace de facebook",
                    notes: "Notas",

                    // Alert
                    warningImg: "El estado visible o no,  se aplicará a todas las imagenes seleccionadas.",
                    Warning: "Advertencia: ",

                    // Menu list configuration
                    listMenu: "Lista del Menú",
                    nameOptionMenu: "Nombre menú",
                    contentMenu: "Contenido menú",
                    orderOptionMenu: "Orden del menú",

                    // Buy
                    buy: "Comprar",
                    formClient: "Formulario de cliente",
                    socialMedia: "¿Por cuál medio encontró la página?",
                    marketPlaceSeller: "Vendedor en marketplace",
                    deliveryQuestion: "¿Desea delivery?",
                    request: "Solicitar refrigerador",
                    alertNoteDelivery: "Por favor, asegurese de colocar la información correcta. Se le estará llamado lo más pronto posible para confirmar la entrega.",
                    alertNoteVisit: "Por favor, asegurese de colocar la información correcta, en la notas debe indicar la hora en que llegará a visitarnos, será un placer atenderle",
                    answerDelivery: "Quiero delivery",
                    answerVisit: "Iré al lugar",
                    errorSocialMedia: "Debe seleccionar el medio por que encontró la página, por favor.",
                    msgOurAddress: "Esta es nuestra dirección",
                    acceptCaptcha: "Por favor, acepta el captcha",

                    //Permission 
                    titlePermissionList: "Permisos",
                    btnAssing: "Asignar permisos",
                    cantPermission: "Para asignar permisos, debe seleccionar al menos uno",

                    //Order requests
                    titleRequests: "Ordenes de compra",
                    deliveryDate: "Fecha de entrega",


                    // Mark as sold
                    btnMangement: "Gestionar producto",

                    clientData: "Datos del cliente",
                    orderData: "Datos de la orden",
                    detailPurchaseTitle: "Detalle de la orden",

                    functionKey: "Clave del permiso",
                    copySuccessfully: "Enlace copiado correctamente",

                    // Variables to church
                    listChurches: "Lista de iglesias",
                    churchCDI: "CDI",
                    placeHolderChurchName: "Ingrese el nombre de la iglesia",
                    pHCDIName: "Ingrese el CDI",
                    selectChurch: "Seleccione la iglesia",
                    errorSelectedChurch: "Para asignar permisos, debe seleccionar una iglesia",
                    church: "Iglesia",
                    churchCod: "ES",

                    //Rol
                    rolTitle: "Lista de Roles",

                    // Catalogs
                    catalogTitle: "Catálogo",
                    accountNumber: "Número de cuenta",
                    accountName: "Nombre de la cuenta",
                    accountType: "Tipo de cuenta",
                    accountList: "Lista de cuentas",
                    errorAccountNumberDup: "El número de cuenta no puede duplicarse",
                    classificationAccount: "Clasificación",


                    // petty cash
                    replenishmenment: "Reposición de caja chica",
                    documentNumber: "N° Documento",
                    receiptNumber: "Comprobante N°",
                    summaryDescription: "Descripción resumida",
                    amountO:"Monto",
                    dateF:"Fecha",
                    expense:"Gasto",
                    income:"Ingreso",
                    movementType:"Tipo de movimiento",
                    balance:"Saldo",
                    createRequest:"Crear Solicitud",

                    completeFields:"Complete los campos requeridos por favor",
                    seeReport:"Ver solicitud",
                    seeCompleteReport:"Ver reporte completo",
                    isEditableA:"¿Es editable?",
                    changePassword:"Cambiar contraseña",
                    newPassword:"Nueva contraseña",
                    confirmNewPassword:"Confirmar nueva contraseña",


                }
            },
            en: {
                translations: {
                    //HOME PAGE
                    aboutUs: "A little about us...",
                    textAboutUs: "It was born with the idea of ​​serving thousands of people who are looking for a beautiful refrigerator at a great price. We understand reality and that is why we give our best to satisfy your needs day by day.",
                    mission: "Mission",
                    textMission: "Our mission is to help people take care of their food by providing affordable refrigerators.",
                    vision: "Vision",
                    textVision: "To be a leading company in helping people take care of their food through used refrigerators in very good condition. Become a highly competitive, reliable and responsible company for the products we handle.",
                    deliveryService: "Delivery service",
                    textDeliveryService: "We work day by day to provide the best refrigerator delivery service 😎,                    We have a team with a lot of experience delivering refrigerators to your house and leaving it properly connected 🤩. If you don't have a car to take the refrigerator, don't worry, we are your best option.",
                    repairService: "Repair Service",
                    textRepairService: "Is your refrigerator not working? Does your food spoil? Does it not freeze? Are you worried about taking care of your food?, If you identify with any of these problems and your refrigerator is a Kenmore or LG brand, don't worry anymore, we'll fix it for you 🤩, we have specialized technicians who can help you so don't hesitate, put your refrigerator in good hands.",
                    ourServices: "Our services",
                    seeProducts: "See products",
                    lookingFor: "What are you looking for?",
                    textSingIn: "Enter your email & password to login",
                    textUser: "Email or user",
                    see: "See",
                    information: "Information",
                    copyLink: "Copy link",
                    copySuccessfully: "Link copied successfully",
                    deliveryWarning: "Deliveries of refrigerators that are greater than 50 miles away are not made.",
                    btnManagmentImage: "Manage images",
                    myRequest: "My requests",
                    requestStatus: "Request status",
                    beginingDate: "Begining date",
                    endingDate: "Ending date",
                    errorDates: "You must select begining date and ending date",
                    seller: "Seller",
                    mySales: "My sales",
                    titleAlertD: "Are you sure?",
                    descripAlertD: "Once deleted, you will not be able to recover this file!",
                    msgDeleted: "Removed successfully",
                    msgRejectDeleted: "Your file is safe!",
                    errorTask: "Something was wrong!",
                    errorSingIn: "Oppss.. The username or password is incorrect",
                    seeDetail: "See detail",
                    cancelSale: "Cancel Sale",
                    msgSale: "Sale successfully canceled",
                    descripAlertInvoice: "Once canceled, it wil not be possible to recover this information!",
                    btnDownload: "Download images",


                    //END HOME PAGE

                    //MENU
                    titleListFirstLevel: "First level menu",
                    titleListSecondLevel: "Second level menu",
                    titleListThirdLevel: "Third level menu",
                    nameMenu: "Menu name",
                    menuFirstLevel: "First level menu",
                    menuSecondLevel: "Second level menu",
                    menuThirdLevel: "Third level menu",
                    iconName: "Icon",
                    pathMenu: "Path",
                    moduleName: "Module name",
                    titleModules: "Modules",
                    titleFunctions: "Functions",
                    functionName: "Function name",
                    //END MENU


                    // General labels
                    General: "General",
                    Dashboards: "Dashboards",
                    Widgets: "Widgets",
                    Dashboard: "Dashboard",
                    Default: "Default",
                    Ecommerce: "Ecommerce",
                    Chart: "Chart",
                    Applications: "Applications",
                    ReadytouseApps: "Ready to use Apps",
                    email: "Email",
                    placeholderEmail: "Enter the email",
                    cancel: "Cancel",
                    create: "Create",
                    update: "Update",
                    delete: "Delete",
                    close: "Close",
                    processing: "Processing...",
                    show: "Show",
                    firstName: "First Name",
                    lastName: "Last Name",
                    placeHolderFirstName: "First Name",
                    placeHolderLastName: "Last Name",
                    placeHolderPhoneNumber: "Phone Number",
                    errorFirstName: "You must enter the first name",
                    errorLastName: "You must enter the last Name",
                    errorPhoneNumber: "You must enter the phone number",
                    errorEmail: "You must enter a valid email",
                    errorAddress: "You must enter an address",
                    errorRequired: "This field cannot be empty",
                    SingIn: "You do not have a registered user ",
                    successCreated: "Record created successfully",
                    successUpdated: "Record updated successfully",
                    country: "Country",
                    department: "Department",
                    createdDate: 'Created Date',
                    actions: "Actions",
                    phoneNumber: "Phone number",
                    address: "Address",
                    placeHolderAddress: "Enter the address",
                    editInfo: "Edit information",
                    createInfo: "Add information",
                    selectStatus: "Status",
                    errorStatus: "You must select a status",
                    description: "Description",
                    placeHolderDescription: "Enter description",
                    newRecord: "New record",
                    placeHolderGeneralName: "Type a name",
                    document: "Documento",
                    placeHolderDocument: "Ingrese el número de documento",
                    date: 'Date',
                    search: 'Search',
                    pay: "Pay",
                    price: "Price",
                    code: "Code",
                    quantity: "Quantity",
                    services: "Services",
                    home: "Home",
                    singInP: "Sing In",
                    products: "Products",
                    hide: "Hide",
                    loading: "Loading...",
                    placeHolderDocument: "Enter number of document",
                    errorLoginSesion: "Back to login, please",
                    chargeDelivery: "Costo por entrega",

                    //employeesComponent
                    employee: "Employee",
                    employees: "Employees",
                    placeholderEmployees: "Select an employee",
                    errorEmployee: "You must select an employee",
                    titleEmployeeCreate: "Add Employee",
                    subtitleEmployeeCreate: "Enter employee information",
                    positionCompany: "Position in the company",
                    selectCountry: "Select a country",
                    selectDepartment: "Select a department",
                    selectMunicipio: "Select a Municipio",
                    dui: "Dui",
                    placeHolderDui: "Dui",
                    placeHolderPositionCompany: "Select a position in the company",
                    placeHolderCountry: "Select a country",
                    placeHolderDepartment: "Select a department",
                    placeHolderMunicipio: "Select a Municipio",
                    errorDui: "You must enter a dui",
                    errorPositionCompany: "You must select a position in the company",
                    errorCountry: "You must select a country",
                    // List employee
                    titleListEmployee: "List of Employees",
                    subtitleListEmployee: "Employee information",
                    //UsersCreateComponent
                    user: "User",
                    users: "Users",
                    userName: "User name",
                    errorUserName: "You must enter a username",
                    titleUserCreate: "Add user",
                    subtitleUserCreate: "Here you can add new users",
                    placeholderUserName: "Enter the username",
                    password: "Password",
                    placeholderPassword: "Enter the password",
                    errorPassword: "You must enter a password",
                    passConfirm: "Confirm password",
                    placeholderPassConfirm: "Enter password confirmation",
                    errorPassConfirm: "Confirm your password",
                    errorMatchPassword: "Passwords don't match",
                    rol: "Select a rol",
                    errorRol: "To assign permissions, you must selected a rol please",

                    //Users list
                    listUsers: "Users list",
                    nameFirst: "First name",
                    nameLast: "Last name",
                    usrName: "User name",
                    headPopPass: "The password must contain",
                    bodyPopPass1: "1- At least 6 characters.",
                    bodyPopPass2: "2- Include at least one capital letter.",
                    bodyPopPass3: "3- At least one number.",
                    bodyPopPass4: "4- At least one of the symbols(.*@~#-+:;=^%/)",

                    //Category
                    categories: "Categories",
                    category: "Category",
                    placeholderCategory: "Enter category",
                    placeholderSelectCategory: "You must select a category",
                    titleListCategories: "Categories list",

                    //subcategories
                    titleListSubcategories: "Subcategories list",
                    subcategory: "Subcategory",

                    // Brand
                    nameBrand: "Brand",
                    titleListBrands: "Brand list",
                    descriptionBrand: "Description",

                    //Providers
                    parentProvider: "Providers",
                    titleListProviders: "Providers list",
                    provider: "Providers",
                    placeHolderProvider: "Enter the provider name",
                    giro: "Commercial business",
                    placeHolderGiro: "Enter the comemercial business",
                    errorProvider: "You must choose a provider",

                    // Product
                    product: "Product",
                    titleEditProduct: "Edit product",

                    titleCreateProduct: "Add product",
                    productName: "Product Name",
                    productCode: "Product code",
                    stockLimit: "Stock",
                    percentageProfit: "Percentage of profit",
                    barcode: "Barcode",
                    amount: "Number",
                    unitPrice: "Unit purchase price",
                    saleUnitPrice: "Unit sale price",
                    productPhotos: "Choose images",
                    errorProductName: "You must enter a product name",
                    errorCategory: "You must choose a category",
                    errorSubCategory: "You must choose a subcategory",
                    errorStockLimit: "You must enter a stock limit",
                    errorBarcode: "You must enter a barcode",
                    errorSelectBrand: "You must choose a brand",
                    errorAmount: "You must enter a amount",
                    errorUnitPrice: "You must enter an unit price",
                    errorUnitSalePrice: "You must enter an unit price of sale",
                    errorPercentageProfit: "You must enter a percentage profit",
                    generalInformation: "Purchase order",
                    enterProduct: "Add stock",
                    positiveAnswer: "Yes",
                    negativeAnswer: "No",
                    questionRbtAddProduct: "Would you like to record stock?",
                    choosePicture: "Choose images",
                    parentProducts: "Products",
                    titleListProducts: "Productss list",
                    stockProduct: "Stock",
                    admin: "Manage",
                    productDetail: "Product Detail",
                    errorPrice: "Price cannot be empty and cannot be less than $100",
                    errorDate: "You must enter a right date",

                    //sales
                    titleSales: "Sales list",
                    titleSalesCreate: 'New sale',
                    sale: 'Sale',
                    sales: 'Sales',
                    orderNumber: '#Order',
                    total: 'Total',
                    subTotal: 'Subtotal',
                    discount: 'Discount',
                    client: 'Client',
                    placeHolderClient: 'Select a customer',
                    errorEmptyClient: 'You must select a customer',
                    paymentType: 'Payment type',
                    text: 'Text',
                    searchProduct: 'Search products',
                    searchTooltipSaleBar: 'Search by barcode',
                    searchTooltipSaleText: 'Search by text',
                    totalPay: "Total to pay",
                    warningSaleQuantity: "You must add at least one product",
                    invalidStock: "Insufficient stock",
                    detailSaleTitle: "Sales order detail",
                    image: "Image",
                    priority: "Priority",
                    visibleCustomer: "Visible to the customer",
                    placeHolderSelectProdut: "Choose a product",
                    errorSelectProduct: "You must select a product",
                    errorSelectImg: "You must select an image",

                    btnSearch: "Search",
                    errorPriority: "You must enter a valid number",
                    msgExistImage: "There are not images for this product",
                    addStockMsg: "Add stock",
                    productDiscount: "Product discount",

                    // Clients
                    clients: "Clients",
                    linkMeta: "Link Facebook",
                    placeHolderLinkMeta: "Link facebook",
                    notes: "Notes",

                    // Alert
                    warningImg: "The status visivle or not will be applied to all selected images.",
                    Warning: "Warning: ",

                    // Menu list

                    listMenu: "Menu list",
                    nameOptionMenu: "Menu name",
                    contentMenu: "Menu content",
                    orderOptionMenu: "Menu order",

                    //Buy
                    buy: "Buy",
                    formClient: "Customer form",
                    socialMedia: "How did you find the page?",
                    marketPlaceSeller: "Marketplace seller",
                    deliveryQuestion: "Do you want delivery?",
                    request: "Request refrigerator",
                    alertNoteDelivery: "Please make sure you enter the correct information. You will be called as soon as possible to confirm delivery.",
                    alertNoteVisit: "Please, make sure you put the correct information, in the notes you can put the time you will arrive to visit us.",
                    answerDelivery: "I want delivery",
                    answerVisit: "I will go to the place",
                    errorSocialMedia: "You must select how did you find the page",
                    msgOurAddress: "This is our address",
                    acceptCaptcha: "Please accept the captcha",

                    // Permission
                    titlePermissionList: "Permissions",
                    btnAssing: "Assign permissions",
                    cantPermission: "To assign permissions, you must select at least one",

                    //Order requests
                    titleRequests: "Purchase orders",
                    btnMangement: "Manage Product ",
                    deliveryDate: "Delivery date",


                    clientData: "Client data",
                    orderData: "Order data",
                    detailPurchaseTitle: "Order detail",
                    functionKey: "Permission key",

                    // Variables to church
                    listChurches: "Lista de iglesias",
                    churchCDI: "CDI",
                    placeHolderChurchName: "Enter the church name",
                    pHCDIName: "Enter the CDI",
                    selectChurch: "Select a church",
                    errorSelectedChurch: "To assign permissions, you must selected a church please.",
                    church: "Church",
                    churchCod: "ES",

                    // Rol
                    rolTitle: "Role List",

                    // Catalog
                    catalogTitle: "Catalog",
                    accountNumber: "Account Number",
                    accountName: "Account Name",
                    accountType: "Account Type",
                    accountList: "Account List",
                    errorAccountNumberDup: "Account number cannot be duplicated",
                    classificationAccount: "Classification",


                    // Petty cash
                    replenishmenment: "Petty cash replenishment",
                    documentNumber: "N° Document",
                    receiptNumber: "Receipt N°",
                    summaryDescription: "Summary description",
                    amountO:"Amount",
                    dateF:"Date",
                    expense:"Expense",
                    income:"Income",
                    movementType:"Movement type",
                    balance:"Balance",
                    createRequest:"Create request",


                    completeFields:"You should complete fields, please",

                    seeReport:"See request",
                    seeCompleteReport:"See full report",
                    isEditableA:"Editable",
                    changePassword:"Change password",
                    newPassword:"New password",
                    confirmNewPassword:"Confirm new password",

                }
            },
            cn: {
                translations: {
                    General: "一般",
                    Dashboards: "小部件",
                    Widgets: "小部件",
                    Dashboard: "仪表板",
                    Default: "默认",
                    Ecommerce: "电子商务",
                    Chart: "图表",
                    Applications: "应用领域",
                    ReadytouseApps: "准备使用的应用程序"
                }
            },
            ae: {
                translations: {
                    General: "جنرال لواء",
                    Dashboards: "الحاجيات",
                    Widgets: "لوحات القيادة ",
                    Dashboard: "لوحة القيادة",
                    Default: "إفتراضي",
                    Ecommerce: "التجارة الإلكترونية",
                    Chart: "مخطط",
                    Applications: "التطبيقات",
                    ReadytouseApps: "جاهز لاستخدام التطبيقات"
                }
            },
            du: {
                translations: {
                    General: "Algemeen",
                    Dashboards: "Dashboards",
                    Widgets: " widgets",
                    Dashboard: "Dashboard",
                    Default: "Standaard",
                    Ecommerce: "E-commerce",
                    Chart: "Grafiek",
                    Applications: "Toepassingen",
                    ReadytouseApps: "Klaar om apps te gebruiken"
                }
            },
            fr: {
                translations: {
                    General: "Générale",
                    Dashboards: "Tableaux de bord",
                    Widgets: " widgets",
                    Dashboard: "Tableau de bord",
                    Default: "Défaut",
                    Ecommerce: "Commerce électronique",
                    Chart: "Graphique",
                    Applications: "Applications",
                    ReadytouseApps: "Applications prêtes à l'emploi"
                }
            },
            pt: {
                translations: {
                    General: "Geral",
                    Dashboards: "Painéis",
                    Widgets: " Widgets",
                    Dashboard: "painel de controle",
                    Default: "Padrão",
                    Ecommerce: "Comércio eletrônico",
                    Chart: "Gráfico",
                    Applications: "Formulários",
                    ReadytouseApps: "Aplicativos prontos para usar"
                }
            }
        },
        fallbackLng: "en",
        debug: false,

        // have a common namespace used around the full app
        ns: ["translations"],
        defaultNS: "translations",

        keySeparator: false, // we use content as keys

        interpolation: {
            escapeValue: false
        }
    });

export default i18n;
