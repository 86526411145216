import React, { Fragment, useState, useEffect } from "react";
import Breadcrumb from "../../layout/breadcrumb";
import { Container, Row, Col, Card, CardHeader, Table, CardBody, Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import axios from "axios";
import 'devextreme/dist/css/dx.material.teal.light.css';
import PopupChurches from "./popupChurches";
import DataGrid, { Column, Editing, Popup, Paging, Lookup, Form, SearchPanel, Scrolling, Pager, Export, HeaderFilter, RequiredRule } from 'devextreme-react/data-grid';
import 'devextreme-react/text-area';
import { useTranslation } from 'react-i18next';

const ChurchesList = () => {

  const infoUserLogin = JSON.parse(localStorage.getItem('infoUserLogin'));

  // To traslate the words
  const { t } = useTranslation();

  // Spaces
  const tab = '\u00A0';

  // To get the information of the sub categories
  const [dataChurches, setDataChurches] = useState([]);

  // To get the information of the categories
  const [listChurches, setListChurches] = useState([]);

  // To get the list of status
  const [listStatus, setListStatus] = useState([]);

  // To determinate if the event is create or edit:  edit:true and create:false
  const [isEdit, setIsEdit] = useState(false);

  const [controlModal, setControlModal] = useState(false);

  // To get the status of Church
  const [statusChurch, setStatusChurch] = useState('');

  const [readOnlyF, setReadOnlyF] = useState(true);

  const [permissionsModule, setPermissionsModule] = useState([]);

  // Use effect is launch one time when the page load
  useEffect(() => {

    if (infoUserLogin !== null && infoUserLogin !== undefined) {

      // To determinate if the user logged is Admin
      setReadOnlyF(infoUserLogin.idRol == 1 ? false : true);

      axios
        .get(`${process.env.REACT_APP_DOMAIN_SERVER}api/getChcById/${infoUserLogin.IdChurch}`)
        .then((response) => {

          setListChurches(response.data.churches);

        })
        .catch((error) => {
          console.log(error);
        });

      // Get permission
      axios
        .get(`${process.env.REACT_APP_DOMAIN_SERVER}/api/getPermission/${infoUserLogin.idRol}`)
        .then((result) => {
          setPermissionsModule(result.data.response);
        })
        .catch((error) => {
          console.log(error);
        });



    } else {
      setPermissionsModule([]);
    }


  }, []);

  // Get the list of status only load once time
  useEffect(() => {
    // We pass like parameter 1 because 1 has the general status
    axios
      .get(`${process.env.REACT_APP_DOMAIN_SERVER}api/processState/${1}`)
      .then((response) => {
        setListStatus(response.data.listStatus);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const cellRenderAction = (data) => {
    return <div align="center"><i style={{ cursor: 'pointer' }} className="icofont icofont-ui-edit" onClick={() => editChurch(data)} /></div>;
  }

  const createChurch = (e) => {
    setDataChurches(
      {
        churchName: "",
        churchCDI: "",
        churchStatus: "",
        addressChurch: "",
        phoneNumberChurch: "",
        churchCodEs: "",

      }
    );
    setIsEdit(false);
    setControlModal(!controlModal);
  };

  const editChurch = (e) => {
    setIsEdit(true);
    setDataChurches(e.data);
    setControlModal(!controlModal);
    setStatusChurch(e.data.churchStatus);
  }

  return (
    <Fragment>
      <Breadcrumb parent="Churches" title={t("listChurches")} />
      <Container fluid={true}>
        <Row className="justify-content-md-center">
          <Col sm="12">
            <Card>
              <CardBody>
                <Row >
                  <Col sm="12" lg="12" xl="12">
                    <div className="table-responsive">
                      <div id="data-grid-demo" className="table-primary">

                        {/* Popup */}
                        <PopupChurches
                          controlModal={controlModal} setControlModal={setControlModal}
                          dataChurches={dataChurches}
                          isEdit={isEdit}
                          listStatus={listStatus}
                          setListChurches={setListChurches}
                          statusChurch={statusChurch} setStatusChurch={setStatusChurch}
                          readOnlyF={readOnlyF}
                        />

                        {
                          permissionsModule.find(v => { return v.functionName === 'createChurch' }) ?
                            (
                              <div className="btn-showcase ">
                                <Button className="btn-pill" color="primary" onClick={createChurch}><i className="icofont icofont-ui-add"></i>{tab + tab}{t('create')}</Button>
                              </div>
                            ) :
                            ''
                        }


                        <DataGrid
                          dataSource={listChurches}
                          keyExpr="IdChurch"
                          showBorders={true}
                          rowAlternationEnabled={true}
                          columnAutoWidth={true}
                          t={t}
                        >
                          <HeaderFilter visible={true} allowSearch={true} />
                          <Export enabled={true} />
                          <SearchPanel visible={true} highlightCaseSensitive={true} width={350} />
                          <Scrolling
                            useNative={false}
                            scrollByContent={true}
                            scrollByThumb={true}
                            showScrollbar="onHover" />
                          <Paging defaultPageSize={5} />
                          <Pager
                            showPageSizeSelector={true}
                          />

                          <Editing
                            mode="popup"
                            allowUpdating={false}
                            allowAdding={false}
                            allowDeleting={false}>
                          </Editing>

                          <Column caption={t('actions')} cellRender={cellRenderAction} width={100} />
                          <Column dataField="churchName" caption={t('name')} >
                            <RequiredRule />
                          </Column>
                          <Column dataField="churchCDI" caption={t('churchCDI')} >
                            <RequiredRule />
                          </Column>

                          <Column dataField="churchCodEs" caption={t('churchCod')} >
                            <RequiredRule />
                          </Column>

                          <Column dataField="phoneNumberChurch" caption={t('phoneNumber')} >
                            <RequiredRule />
                          </Column>
                          <Column dataField="addressChurch" caption={t('address')} >
                            <RequiredRule />
                          </Column>
                          <Column dataField="churchStatus" caption={t("selectStatus")} >
                            <Lookup dataSource={listStatus} valueExpr="id" displayExpr="name" />
                          </Column>
                        </DataGrid>
                      </div>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );

};

export default ChurchesList;
