import React, { Fragment, useState, useEffect } from "react";
import Breadcrumb from "../../layout/breadcrumb";
import { Container, Row, Col, Card, CardHeader, Table, CardBody, Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import axios from "axios";
import 'devextreme/dist/css/dx.material.teal.light.css';
import PopupMenu from "./popupRol";
import DataGrid, { Column, Editing, Popup, Paging, Lookup, Form, SearchPanel, Scrolling, Pager, Export, HeaderFilter, RequiredRule } from 'devextreme-react/data-grid';
import 'devextreme-react/text-area';
import { useTranslation } from 'react-i18next';

const ListRoles = () => {

  // Get the information of user

  const infoUserLogin = JSON.parse(localStorage.getItem('infoUserLogin'));

  // To traslate the words
  const { t } = useTranslation();

  // Spaces
  const tab = '\u00A0';

  // IdChurch of user logged
  const [idChurchUserLogged, setIdChurchUserLogged] = useState('');

  // To get the information of the menu to edit
  const [dataRoles, setDataRoles] = useState([]);

  const [readOnlyForm, setReadOnlyForm] = useState(false);

  // To get the information of the menu to edit
  const [dataChurches, setDataChurches] = useState([]);


  // To get the list information of the menu
  const [listRoles, setListRoles] = useState([]);

  // To get the list of status
  const [listStatus, setListStatus] = useState([]);

  // To determinate if the event is create or edit:  edit:true and create:false
  const [isEdit, setIsEdit] = useState(false);

  const [controlModal, setControlModal] = useState(false);

  // To get the status of Menu
  const [statusRol, setStatusRol] = useState('');

  // To save the Id of the church to which the role belongs 
  const [idChurch, setIdChurch] = useState('');


  // Use effect is launch one time when the page load
  useEffect(() => {

    if (infoUserLogin !== null && infoUserLogin !== undefined) {

      // Get list of church 
      axios
        .get(`${process.env.REACT_APP_DOMAIN_SERVER}api/getChcById/${infoUserLogin.IdChurch}`)
        .then((response) => {
          setDataChurches(response.data.churches);
        })
        .catch((error) => {
          console.log(error);
        });

      getRolesList();

      // Get the information of the church of the user belongs
      axios
        .get(`${process.env.REACT_APP_DOMAIN_SERVER}api/getInforChurch/${infoUserLogin.idRol}`)
        .then((response) => {
          setIdChurchUserLogged(response.data.infoChurch[0].IdChurch);
        })
        .catch((error) => {
          console.log(error);
        });
      // To determinate if the user logged is Admin
      setReadOnlyForm(infoUserLogin.idRol == 1 ? false : true);
    }

  }, []);

  // Get the list of status only load once time
  useEffect(() => {
    // We pass like parameter 1 because 1 has the general status
    axios
      .get(`${process.env.REACT_APP_DOMAIN_SERVER}api/processState/${1}`)
      .then((response) => {
        setListStatus(response.data.listStatus);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  // Function to allow to get roles
  function getRolesList() {
    axios
      .get(`${process.env.REACT_APP_DOMAIN_SERVER}api/getInfoRolesByIdR/${infoUserLogin.idRol}`)
      .then((response) => {
        setListRoles(response.data.roles);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  // To create edit icon 
  const cellRenderAction = (data) => {
    return <div align="center"><i style={{ cursor: 'pointer' }} className="icofont icofont-ui-edit" onClick={() => editRol(data)} /></div>;
  }

  const createRol = (e) => {
    setDataRoles({
      rol: "",
      description: "",
      IdChurch: "",

    });
    setIsEdit(false);
    setIdChurch(idChurchUserLogged);
    setControlModal(!controlModal);
  };

  const editRol = (e) => {
    setIsEdit(true);
    setDataRoles(e.data);
    setControlModal(!controlModal);
    setStatusRol(e.data.status);
    console.log(e.data.IdChurch);
    setIdChurch(e.data.IdChurch);
    console.log(dataChurches);
  }

  return (
    <Fragment>
      <Breadcrumb parent="Roles" title={t("rolTitle")} />
      <Container fluid={true}>
        <Row className="justify-content-md-center">
          <Col sm="12">
            <Card>
              <CardBody>
                <Row >
                  <Col sm="12" lg="12" xl="12">
                    <div className="table-responsive">
                      <div id="data-grid-demo" className="table-primary">

                        {/* Popup */}
                        <PopupMenu
                          controlModal={controlModal} setControlModal={setControlModal}
                          dataRoles={dataRoles}
                          isEdit={isEdit}
                          listStatus={listStatus}
                          setListRoles={setListRoles}
                          statusRol={statusRol}
                          setStatusRol={setStatusRol}
                          setIdChurch={setIdChurch}
                          idChurch={idChurch}
                          dataChurches={dataChurches}
                          readOnlyForm={readOnlyForm}


                        />

                        <div className="btn-showcase ">
                          <Button className="btn-pill" color="primary" onClick={createRol}><i className="icofont icofont-ui-add"></i>{tab + tab}{t('create')}</Button>
                        </div>

                        <DataGrid
                          dataSource={listRoles}
                          keyExpr="id"
                          showBorders={true}
                          rowAlternationEnabled={true}
                          columnAutoWidth={true}
                          t={t}
                        >
                          <HeaderFilter visible={true} allowSearch={true} />
                          <Export enabled={true} />
                          <SearchPanel visible={true} highlightCaseSensitive={true} width={350} />
                          <Scrolling
                            useNative={false}
                            scrollByContent={true}
                            scrollByThumb={true}
                            showScrollbar="onHover" />
                          <Paging defaultPageSize={10} />
                          <Pager
                            showPageSizeSelector={true}
                          />

                          <Editing
                            mode="popup"
                            allowUpdating={false}
                            allowAdding={false}
                            allowDeleting={false}>
                          </Editing>

                          <Column caption={t('actions')} cellRender={cellRenderAction} width={100} />
                          <Column dataField="churchName" caption={t('church')} ></Column>
                          <Column dataField="rol" caption={t('name')} >
                            <RequiredRule />
                          </Column>
                          <Column dataField="description" caption={t('description')} >
                            <RequiredRule />
                          </Column>
                          <Column dataField="status" caption={t("selectStatus")} >
                            <Lookup dataSource={listStatus} valueExpr="id" displayExpr="name" />
                          </Column>
                        </DataGrid>
                      </div>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );

};

export default ListRoles;
