import React, { Fragment, useState, useEffect } from "react";

import { DataGrid, Column, Lookup, MasterDetail } from 'devextreme-react/data-grid';
import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';
import axios from "axios";
import { useTranslation } from 'react-i18next';
import { Container, Row, Col, Card, CardHeader, Table, CardBody, Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import PopupCatalogSix from "./popupCatalogSix";


const DetailTemplateSixthCat = (props) => {

    // Spaces
    const tab = '\u00A0';

    // Get the information of user
    const infoUserLogin = JSON.parse(localStorage.getItem('infoUserLogin'));

    const [catalogSixthL, setCatalogSixthL] = useState('');
    const { accountName, accountNumber } = props.data.data;

    // To traslate the words
    const { t } = useTranslation();

    // To get the information of the first catalog
    const [dataCatalogSixth, setDataCatalogSixth] = useState([]);

    const [readOnlyForm, setReadOnlyForm] = useState(false);

    // Id of the first level of catalog
    const [idCatFifthLevel, setIdCatFifthLevel] = useState('');

    // To get the list information of the menu
    const [catalogListSixth, setCatalogListSixth] = useState([]);

    // To get the list of status
    const [listStatus, setListStatus] = useState([]);

    // To determinate if the event is create or edit:  edit:true and create:false
    const [isEdit, setIsEdit] = useState(false);

    const [controlModal, setControlModal] = useState(false);

    // To get the status of Menu
    const [statusCatalogSixth, setStatusCatalogSixth] = useState('');

    // Id Classification account CDSP, DSP
    const [idClassificationSix, setIdClassificationSix] = useState('');


    // Is editable catalog account
    const [isEditableA, setIsEditableA] = useState(2);

    // Array  classification
    var classificationAccountSix = [{
        'id': 1,
        'descrip': "CDSP",
    }, {
        'id': 2,
        'descrip': "CSP",
    },
    {
        'id': 3,
        'descrip': "Ninguno",
    },
    ];

    const arrayIsEditableA = [
        { id: 1, text: t('positiveAnswer') },
        { id: 2, text: t('negativeAnswer') },
    ];

    const [dataClassificationSix, setDataClassificationSix] = useState(classificationAccountSix);


    // Get the list of second catalog
    useEffect(() => {
        // getSixthCatalog();
        // We pass like parameter 1 because 1 has the general status
        axios
            .get(`${process.env.REACT_APP_DOMAIN_SERVER}api/processState/${1}`)
            .then((response) => {
                setListStatus(response.data.listStatus);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);


    // Use effect is launch one time when the page load
    useEffect(() => {

        if (infoUserLogin !== null && infoUserLogin !== undefined) {
            setIdCatFifthLevel(props.data.data.IdCatFifthLevel);

            getSixthCatalog();

            // To determinate if the user logged is Admin
            setReadOnlyForm(infoUserLogin.idRol == 1 ? false : true);
        }

    }, []);


    // To create edit icon 
    const cellRenderAction = (data) => {
        return <div align="center"><i style={{ cursor: 'pointer' }} className="icofont icofont-ui-edit" onClick={() => editSixthCatalog(data)} /></div>;
    }

    const editSixthCatalog = (e) => {
        setIsEdit(true);
        setDataCatalogSixth(e.data);
        setControlModal(!controlModal);
        setStatusCatalogSixth(e.data.statusAccount);
        setIdClassificationSix(e.data.IdClassification != null ? e.data.IdClassification : '');
        setIsEditableA(e.data.isEditable != null ? e.data.isEditable : 2);

    }

    const createSixthAccount = (e) => {
        setDataCatalogSixth({
            accountNumber: accountNumber,
            accountName: "",
        });
        setIsEdit(false);
        setControlModal(!controlModal);
        setIdClassificationSix(1);
        setIsEditableA(2);

    };

    return (
        <React.Fragment>
            <div className="master-detail-caption">

                <div className="btn-showcase ">
                    <Row>
                        <Col md="1 mb-3" >
                            <Button className="btn-pill" color="primary" onClick={createSixthAccount}><i className="icofont icofont-ui-add"></i></Button>

                        </Col>
                        <Col md="2 mb-3">
                            <h5>{`${accountName}`}</h5>

                        </Col>

                    </Row>

                </div>
            </div>
            <PopupCatalogSix
                controlModal={controlModal} setControlModal={setControlModal}
                dataCatalogSixth={dataCatalogSixth}
                isEdit={isEdit}
                listStatus={listStatus}
                setCatalogListSixth={setCatalogListSixth}
                statusCatalogSixth={statusCatalogSixth}
                setStatusCatalogSixth={setStatusCatalogSixth}
                readOnlyForm={readOnlyForm}
                accountName={accountName}
                idCatFifthLevel={idCatFifthLevel}
                setCatalogSixthL={setCatalogSixthL}
                idClassification={idClassificationSix}
                setIdClassification={setIdClassificationSix}
                dataClassification={dataClassificationSix}
                isEditableA={isEditableA}
                setIsEditableA={setIsEditableA}
            />


            <DataGrid
                dataSource={getInfoSixthCatalog(props.data.key)}
                showBorders={true}
                columnAutoWidth={true}
                rowAlternationEnabled={true}
                t={t}
            >
                <Column caption={t('actions')} cellRender={cellRenderAction} width={100} />
                <Column dataField="accountNumber" />
                <Column dataField="accountName" />
                <Column dataField="IdClassification" caption={t('classificationAccount')} >
                    <Lookup dataSource={classificationAccountSix} valueExpr="id" displayExpr="descrip" />
                </Column>
                <Column dataField="isEditable" caption={t('isEditableA')} >
                    <Lookup dataSource={arrayIsEditableA} valueExpr="id" displayExpr="text" />
                </Column>
                <Column dataField="statusAccount" caption={t("selectStatus")} >
                    <Lookup dataSource={listStatus} valueExpr="id" displayExpr="name" />
                </Column>
            </DataGrid>
        </React.Fragment>
    );



    // Function that allow get the fourth catalogs
    function getSixthCatalog() {
        axios
            .get(`${process.env.REACT_APP_DOMAIN_SERVER}api/getSixthCatalogsByIdR/${infoUserLogin.IdChurch}`)
            .then((response) => {
                setCatalogSixthL(response.data.sixthCatalog);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    // Function that allow filtering information from sixth catalogs
    function getInfoSixthCatalog(key) {
        return new DataSource({
            store: new ArrayStore({
                data: catalogSixthL,
                key: 'IdCatSixthLevel',
            }),
            filter: ['IdCatFifthLevel', '=', key],
        });
    }
}

export default DetailTemplateSixthCat;
