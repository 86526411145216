import React, { Fragment, useState, useEffect } from "react";
import Breadcrumb from "../../../layout/breadcrumb";
import { Container, Row, Col, Card, CardHeader, Table, CardBody, Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import axios from "axios";
import 'devextreme/dist/css/dx.material.teal.light.css';
import PopupReplenishment from "./popupReplenishment";
import DataGrid, { Column, Editing, Popup, Paging, Lookup, Form, SearchPanel, Scrolling, Pager, Export, HeaderFilter, RequiredRule } from 'devextreme-react/data-grid';
import 'devextreme-react/text-area';
import { useTranslation } from 'react-i18next';
import swal from 'sweetalert';
import { toast } from 'react-toastify';


const ReplenishmentList = () => {

  // Get the information of user

  const infoUserLogin = JSON.parse(localStorage.getItem('infoUserLogin'));

  // To traslate the words
  const { t } = useTranslation();

  // Spaces
  const tab = '\u00A0';

  // To get the information of the sub categories
  const [dataPettyCashActive, setDataPettyCashActive] = useState([]);

  // To get the information of the categories
  const [listDetailPettyCashActive, setListDetailPettyCashActive] = useState([]);

  // To get the list of status
  const [listStatus, setListStatus] = useState([]);

  // To determinate if the event is create or edit:  edit:true and create:false
  const [isEdit, setIsEdit] = useState(false);

  const [controlModal, setControlModal] = useState(false);

  // To save the account Name
  const [accountName, setAccountName] = useState('');
  // To save the movement type
  const [movementType, setMovementType] = useState(1);

  // Catalog complete
  const [catalogComplete, setCatalogComplete] = useState([]);

  // If exists active account
  const [existsAcc, setExistsAcc] = useState(false);

  const [activePettyCash, setActivePettyCash] = useState([]);

  const [loading, setLoading] = useState(false);

  // Info petty cash expects
  const [dataSystemExpect, setDataSystemExpect] = useState([]);



  // Use effect is launch one time when the page load
  useEffect(() => {
    loadDefaultFc();

  }, []);

  // Get the list of status only load once time
  useEffect(() => {
    // We pass like parameter 1 because 1 has the general status
    axios
      .get(`${process.env.REACT_APP_DOMAIN_SERVER}api/processState/${1}`)
      .then((response) => {
        setListStatus(response.data.listStatus);
      })
      .catch((error) => {
        console.log(error);
      });

    // 16.15.0
  }, []);

  function loadDefaultFc() {
    if (infoUserLogin !== null && infoUserLogin !== undefined) {

      axios
        .get(`${process.env.REACT_APP_DOMAIN_SERVER}api/getDetailPettyCashActive/${infoUserLogin.IdChurch}`)
        .then((response) => {
          setListDetailPettyCashActive(response.data.detailPettyCash);
        })
        .catch((error) => {
          console.log(error);
        });

      // Get the list of complete catalog
      getCatalogListComplete();

      // Check if exists an active petty cash
      existActivePettyCash();

    }
  }

  // Function to allow to get catalog first
  function getCatalogListComplete() {
    axios
      .get(`${process.env.REACT_APP_DOMAIN_SERVER}api/geCtgCmp/${infoUserLogin.idRol}`)
      .then((response) => {
        setCatalogComplete(response.data.catalogComp);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  // Function to allow to know if there's a petty cash active
  function existActivePettyCash() {
    axios
      .get(`${process.env.REACT_APP_DOMAIN_SERVER}api/existPtc/${infoUserLogin.IdChurch}`)
      .then((response) => {
        console.log(response.data.data);
        setActivePettyCash(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const cellRenderAction = (data) => {
    return <div align="center">
      <i style={{ cursor: 'pointer' }} className="fa fa-edit fa-2x" onClick={() => editReplenishment(data)} />
      {
        data.data.movementType == 2 ?
          (
            <i style={{ cursor: 'pointer', marginLeft: '10px' }} className="fa fa-trash fa-2x" onClick={() => deleteExpense(data.data)} />
          ) : ''
      }
      {/* <i style={{ cursor: 'pointer', marginLeft: '10px' }} className="fa fa-times fa-2x" onClick={() => deleteExpense(data.data)} /> */}

    </div>;
  }

  const deleteExpense = (e) => {
    swal({
      title: t('titleAlertD'),
      text: t('descripAlertD'),
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((willDelete) => {
        if (willDelete) {
          console.log("Delete Expense");
          axios
            .delete(`${process.env.REACT_APP_DOMAIN_SERVER}api/deleteDetailPttyCash/${e.IdDetailPettyCash}/${e.IdPettyCash}`)
            .then((response) => {
              console.log(response.data);
              if (response.data.error == 0) {
                swal("Eliminado", "Correctamente", "success");
              } else {
                swal("Advertencia", "Algo salio mal", "warning");

              }
              loadDefaultFc();

            })
            .catch((error) => {
              console.log(error);
            })
        }
      });
  }

  const createReplenishment = (e) => {
    if (infoUserLogin !== null && infoUserLogin !== undefined) {

      // Check what the system expects
      axios
        .get(`${process.env.REACT_APP_DOMAIN_SERVER}api/verifyExpenseIn/${infoUserLogin.IdChurch}`)
        .then((response) => {
          console.log(response.data);
          setDataSystemExpect(response.data);
          setDataPettyCashActive(
            {
              dateDetailPettyCash: new Date(),
              documentNumber: "",
              accountNumber: "",
              descriptionDetail: "",
              amount: "",
              IdPettyCash: 1,
            }
          );
          setAccountName('');
          setMovementType(2);
          setIsEdit(false);
          setControlModal(!controlModal);
        })
        .catch((error) => {
          console.log(error);
        });
    }


  };

  const editReplenishment = (e) => {
    setIsEdit(true);
    console.log(e.data);
    setDataPettyCashActive(e.data);
    setMovementType(e.data.movementType);
    setControlModal(!controlModal);
    setAccountName(e.data.accountName);
    setExistsAcc(true);
    setDataSystemExpect({
      limitReceipt: 20,
    });


  }

  const createRequestReplenishment = () => {

    if (activePettyCash.length > 0) {
      swal({
        title: "¿Está seguro de solicitar $ " + activePettyCash[0].amountRequest + " de reposición?",
        text: "Una vez solicitada, ¡No podrá modificar la información!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })
        .then((res) => {
          if (res) {
            setLoading(true);


            if (infoUserLogin !== null && infoUserLogin !== undefined) {
              // Update the status of petty cash 

              const infoUpdate = {
                statusPettyCash: 2,
                whodidit: infoUserLogin.id
              };
              axios.put(`${process.env.REACT_APP_DOMAIN_SERVER}api/updateStcash/${activePettyCash[0].IdPettyCash}`, infoUpdate)
                .then((response) => {

                  toast.info(t('successUpdated'));
                  // loadDetailPettyCash();
                  setLoading(false);

                  loadDefaultFc();

                })
                .catch((errors) => {
                  console.log(errors);
                });
            }
          } else {
            swal("Puedes seguir detallando los gastos!");
          }
        });
    } else {
      swal({
        title: "¡No hay detalle de gastos ingresados!",
        text: "Debe ingresar gastos, para poder hacer la solicitud",
        icon: "warning",
        button: "Okay",
      });
    }

  }

  return (
    <Fragment>
      <Breadcrumb parent="Caja Chica" title={t("replenishmenment")} />
      <Container fluid={true}>
        <Row className="justify-content-md-center">
          <Col sm="12">
            <Card>
              <CardBody>
                <Row >
                  <Col sm="12" lg="12" xl="12">
                    <div className="table-responsive">
                      <div id="data-grid-demo" className="table-primary">

                        {/* Popup */}
                        <PopupReplenishment
                          controlModal={controlModal} setControlModal={setControlModal}
                          dataPettyCashActive={dataPettyCashActive}
                          isEdit={isEdit}
                          setListDetailPettyCashActive={setListDetailPettyCashActive}
                          t={t}
                          movementType={movementType}
                          setMovementType={setMovementType}
                          setAccountName={setAccountName}
                          accountName={accountName}
                          catalogComplete={catalogComplete}
                          existsAcc={existsAcc}
                          setExistsAcc={setExistsAcc}
                          existActivePettyCash={existActivePettyCash}
                          dataSystemExpect={dataSystemExpect}
                        />

                        <div className="btn-showcase ">
                          <Button className="btn-pill" color="primary" onClick={createReplenishment}><i className="icofont icofont-ui-add"></i>{tab + tab}{t('create')}</Button>
                          <Button className="btn-pill" color="primary" onClick={createRequestReplenishment}><i className="icofont icofont-ui-add"></i>{tab + tab}{t('createRequest')}</Button>

                        </div>

                        <DataGrid
                          dataSource={listDetailPettyCashActive}
                          keyExpr="IdDetailPettyCash"
                          showBorders={true}
                          rowAlternationEnabled={true}
                          columnAutoWidth={true}
                          t={t}
                        >
                          <HeaderFilter visible={true} allowSearch={true} />
                          <Export enabled={true} />
                          <SearchPanel visible={true} highlightCaseSensitive={true} width={350} />
                          <Scrolling
                            useNative={false}
                            scrollByContent={true}
                            scrollByThumb={true}
                            showScrollbar="onHover" />
                          <Paging defaultPageSize={50} />
                          <Pager
                            showPageSizeSelector={true}
                          />

                          <Editing
                            mode="popup"
                            allowUpdating={false}
                            allowAdding={false}
                            allowDeleting={false}>
                          </Editing>

                          <Column caption={t('actions')} cellRender={cellRenderAction} width={100} />
                          <Column dataField="accountNumber" caption={"Código contable Cta., subcta. y su sub cta."} >
                            <RequiredRule />
                          </Column>
                          <Column dataField="documentNumber" caption={t('receiptNumber')} >
                            <RequiredRule />
                          </Column>
                          <Column dataField="descriptionDetail" caption={t('summaryDescription')} >
                            <RequiredRule />
                          </Column>
                          <Column dataField="income" caption={t('income')} >
                            <RequiredRule />
                          </Column>
                          <Column dataField="expense" caption={t('expense')} >
                            <RequiredRule />
                          </Column>
                          <Column dataField="balance" caption={t('balance')} >
                            <RequiredRule />
                          </Column>

                        </DataGrid>
                      </div>

                      <div className={loading ? 'loader-wrapper back' : 'loader-wrapper back loderhide'}><div className="loader-index">
                        <span></span></div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );

};

export default ReplenishmentList;
