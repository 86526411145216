import React, { Fragment, useEffect, useState } from 'react';
import Breadcrumb from '../../layout/breadcrumb'
import { Container, Row, Col, Card, CardHeader, CardBody } from 'reactstrap'
import ExchangeHands from '../welcomePage/exchangeHands'


const Default = () => {


  return (
    <Fragment>
      <Breadcrumb parent="Dashboard" title="Bienvenidos" />
      <Container fluid={true}>
        <Row>

          <Card>
            {/* <div style={{ borderRadius:'1%', opacity: '0.1', height: "500px", background: 'url(https://res.cloudinary.com/devjson/image/upload/v1659406049/zowis/WhatsApp_Image_2022-08-01_at_7.58.42_PM_eqytid.jpg) repeat', border: "2px solid black" }}>
            </div> */}

              <div align="center">
                <br />
              </div>
              <div align="center">
                <ExchangeHands />
              </div>
              {/* <CarouselsWelcome /> */}

          </Card>

        </Row>
      </Container>
    </Fragment>
  );
}

export default Default;