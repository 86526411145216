import React, { useState, Fragment, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form'
import axios from "axios";
import { Col, Button, Form, Label, Input, FormGroup, InputGroup, InputGroupText, Row } from 'reactstrap'
import { Typeahead } from 'react-bootstrap-typeahead';
import { useTranslation } from 'react-i18next';
import { classes } from '../../data/layouts';
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import 'devextreme/dist/css/dx.material.teal.light.css';

import { SelectBox } from 'devextreme-react/select-box';


const CreateFormEmployee = () => {

  const defaultLayoutObj = classes.find(item => Object.values(item).pop(1) === 'compact-wrapper');
  const layout = localStorage.getItem('layout') || Object.keys(defaultLayoutObj).pop();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  // Get the information of the logged user
  const infoUserLogin = JSON.parse(localStorage.getItem('infoUserLogin'));

  const { register, handleSubmit, formState: { errors }, control } = useForm();
  const [validateClass, setValidateClass] = useState(false);
  const [roles, setRoles] = useState([]);
  const [dataChurches, setDataChurches] = useState([]);
  const [idChurchSelected, setIdChurchSelected] = useState('');

  const [country, setCountry] = useState([]);
  const [department, setDepartment] = useState([]);
  const [municipio, setMunicipio] = useState([]);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [dui, setDui] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [idRol, setIdRol] = useState('');
  const [idCountry, setIdCountry] = useState('');
  const [idDepartment, setIdDepartment] = useState('');
  const [idMunicipio, setIdMunicipio] = useState('');
  const [address, setAddress] = useState('');


  // Constant that allow traslate
  const { t } = useTranslation();

  // Define error array
  const [error, setError] = useState(
    {
      'firstName': '',
      'lastName': '',
      'dui': '',
      'phoneNumber': '',
      'email': '',
      'idRol': '',
      'idCountry': '',
      'idDepartment': '',
      'idMunicipio': '',
      'address': ''
    }
  );


  const multiple = false

  const onSubmit: SubmitHandler<FormValues> = data => {
    // If all validations are met we'll call register method
    createEmployee(data);

  }

  // UseEfecct is launched just opening the page
  useEffect(() => {

    // Get the list of roles and countries
    axios
      .get(`${process.env.REACT_APP_DOMAIN_SERVER}api/roles`)
      .then((response) => {
        // setRoles(response.data.roles);
        setCountry(response.data.countries);
      })
      .catch((error) => {
        console.log(error);
      });

    // Get the churches
    if (infoUserLogin !== null && infoUserLogin !== '') {
      axios
        .get(`${process.env.REACT_APP_DOMAIN_SERVER}api/getChcById/${infoUserLogin.IdChurch}`)
        .then((response) => {
          setDataChurches(response.data.churches);
        })
        .catch((error) => {
          console.log(error);
        });

    }

  }, []);

  // Function that allow to know when a church changed value
  const handleChangeChurch = (newvalue) => {
    if (newvalue.value !== null) {

      console.log(newvalue);

      // Set the id
      if (newvalue.value !== undefined) {
        getRolesByIdChurch(newvalue.value.IdChurch);

        setIdChurchSelected(newvalue.value.IdChurch);
      } else {
        setIdChurchSelected('');
      }

    } else {
      // Clear the information 
      setIdChurchSelected('');

    }
  }

  // Get the list of roles 
  const getRolesByIdChurch = (ev) => {
    axios
      .get(`${process.env.REACT_APP_DOMAIN_SERVER}api/searchRolById/${ev}`)
      .then((response) => {
        setRoles(response.data.roles);
      })
      .catch((error) => {
        console.log(error);
      });
  }


  // Function that executed when the deparment change value

  const handleCountryChanged = (newValue) => {
    if (newValue.value !== null && newValue.value !== undefined) {
      // Set the id of country

      if (newValue.value.length === undefined) {
        setIdCountry(newValue.value.id);
        // Set the id department
        setIdDepartment('');
        setDepartment([]);
        setIdMunicipio('');
        setMunicipio([]);
        // Get the departments by id country
        axios
          .get(`${process.env.REACT_APP_DOMAIN_SERVER}/api/departments/${newValue.value.id}`)
          .then((payload) => {
            // If everything is good, load the array of departments in the Typeahead or select
            setDepartment(payload.data);
          })
          .catch((error) => {
            setDepartment([]);

          });
      }



    } else {
      // Clear the information of country
      setIdCountry('');


      // Reset department's values
      setIdDepartment('');
      setDepartment([]);

      // Set up the array of municipio to empty

      setIdMunicipio('');
      setMunicipio([]);

    }
  }

  const handleChangedDepartment = (newvalue) => {

    if (newvalue.value !== null && newvalue.value !== undefined) {
      if (newvalue.value.length === undefined) {
        // Set the id department
        setIdDepartment(newvalue.value.id);
        setIdMunicipio('');
        setMunicipio([]);

        // Get the municipios by id depto
        axios
          .get(`${process.env.REACT_APP_DOMAIN_SERVER}/api/municipios/${newvalue.value.id}`)
          .then((payload) => {
            setMunicipio(payload.data);
          })
          .catch((error) => {

            // If something was wrong we clear the information of municipios
            setMunicipio([]);
          });
      }

    } else {

      // Reset department's values
      setIdDepartment('');
      // Set up the array of municipio to empty
      setIdMunicipio('');
      setMunicipio([]);
    }
  }

  const handleSelectRol = newvalue => {

    if (newvalue.value !== null && newvalue.value !== undefined) {
      // Set the id
      setIdRol(newvalue.value.id);
    } else {
      // Clear the information of Rol
      setIdRol('');
    }

  }


  const handleChangeMunicipio = (newvalue) => {

    if (newvalue.value !== null && newvalue.value !== undefined) {
      // Set the id
      setIdMunicipio(newvalue.value.id);


    } else {
      // Clear the information of municipio
      setIdMunicipio('');

    }
  }

  // Function that allow save the record
  const createEmployee = (data) => {

    if (infoUserLogin.id !== null && infoUserLogin.id !== '') {
      if (idRol !== "" && idCountry !== "") {
        setLoading(true);
        const info = {
          firstName: data.firstName,
          lastName: data.lastName,
          address: data.address,
          phoneNumber: data.phoneNumber,
          email: data.email,
          dui, idRol, idCountry,
          idDepartment, idMunicipio,
          whoCreated: infoUserLogin.id
        };

        axios.post(`${process.env.REACT_APP_DOMAIN_SERVER}api/employees`, info)
          .then((res) => {
            toast.info(t('successCreated'));
            setTimeout(() => {
              setLoading(false);
              navigate(`${process.env.PUBLIC_URL}/app/employees/listEmployees/${layout}`);
            }, 400);
          })
          .catch((err) => {
            setLoading(false);
            setError(err.response.data.messages);
          });
      }
    } else {
      setTimeout(() => {
        toast.error(t('errorLogin'));
      }, 200);
    }

  };

  return (
    <Fragment>
      <Form className={`needs-validation tooltip-validation ${validateClass ? 'validateClass' : ''}`} noValidate="" onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col md="6 mb-2">
            <Label>{t("firstName")}</Label>
            <input className="form-control btn-pill" name="firstName" type="text" autoComplete='off' placeholder="Nombres" onChange={e => setFirstName(e.target.value)} {...register('firstName', { required: true })} />
            <span>{errors.firstName && t("errorFirstName")}</span>
            {/* <div className="valid-feedback">{"Looks good!"}</div> */}
          </Col>
          <Col md="6 mb-2">
            <Label>{t("lastName")}</Label>
            <input className="form-control btn-pill" name="lastName" type="text" placeholder="Apellidos" autoComplete='off' onChange={e => setLastName(e.target.value)} {...register('lastName', { required: true })} />
            <span>{errors.lastName && t("errorLastName")}</span>
            <div className="valid-feedback">{"Looks good!"}</div>
          </Col>

          <Col md="6 mb-2">
            <Label>{"Dui"}</Label>
            <input className="form-control btn-pill" name="dui" type="text" autoComplete='off' placeholder="Dui" onChange={e => setDui(e.target.value)} />
          </Col>
          <Col md="6 mb-2">
            <Label>{t("phoneNumber")}</Label>
            <input className="form-control btn-pill" name="phoneNumber" type="text" autoComplete='off' placeholder="Teléfono" onChange={e => setPhoneNumber(e.target.value)} {...register('phoneNumber', { required: true })} />
            <span>{errors.phoneNumber && t("errorPhoneNumber")}</span>
            <div className="valid-feedback">{"Looks good!"}</div>
          </Col>

          <Col md="6 mb-2">
            <Label>{t("email")}</Label>
            <input className="form-control btn-pill" name="email" type="text" autoComplete='off' placeholder={t('email')} onChange={e => setEmail(e.target.value)} {...register('email', {
              required: true,
              pattern: /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
            })} />
            <span>{errors.email && t("errorEmail") || error.email}</span>
          </Col>

          <Col md="6 mb-2" >
            <Label>{t("selectChurch")}</Label>
            <SelectBox
              dataSource={dataChurches}
              displayExpr="churchName"
              onValueChanged={handleChangeChurch}
              searchEnabled={true}
              className={'form-control dxSelectBorder'}
              placeholder={t('selectChurch')}
              showClearButton={true}
              name="selectChurch"
            />
            <input type="hidden" />
            <span>{((idChurchSelected === '' || idChurchSelected === undefined) && validateClass) && t("errorSelectedChurch")}</span>
          </Col>

          <Col md="6 mb-2">
            <Label>{t("positionCompany")}</Label>
            <SelectBox
              dataSource={roles}
              displayExpr="rol"
              onValueChanged={handleSelectRol}
              searchEnabled={true}
              className={'form-control dxSelectBorder'}
              placeholder={t('placeHolderPositionCompany')}
              showClearButton={true}
              name="selectRol"
            />
            <input type="hidden" />
            <span>{(idRol == '' && validateClass) && t("errorPositionCompany")}</span>
            <div className="valid-feedback">{"Looks good!"}</div>
          </Col>

          <Col md="6 mb-2">
            <Label>{t("selectCountry")}</Label>

            <SelectBox
              dataSource={country}
              displayExpr="name"
              onValueChanged={handleCountryChanged}
              value={country.find(v => v.id === idCountry)}
              searchEnabled={true}
              className={'form-control dxSelectBorder'}
              placeholder={t('placeHolderCountry')}
              showClearButton={true}
              name="selectCountry"
            />
            <input type="hidden" />
            <span>{(idCountry === '' && validateClass) && t("errorCountry")}</span>

          </Col>
          <Col md="6 mb-2">
            <Label>{t("selectDepartment")}</Label>
            <SelectBox
              dataSource={department}
              displayExpr="name"
              onValueChanged={handleChangedDepartment}
              value={department.find(v => v.id === idDepartment)}
              searchEnabled={true}
              className={'form-control dxSelectBorder'}
              placeholder={t('placeHolderDepartment')}
              showClearButton={true}
              name="selectDepartment"
            />
          </Col>


          <Col md="6 mb-2">
            <Label>{t("selectMunicipio")}</Label>
            <SelectBox
              dataSource={municipio}
              displayExpr="name"
              onValueChanged={handleChangeMunicipio}
              value={municipio.find(v => v.id === idMunicipio)}
              searchEnabled={true}
              className={'form-control dxSelectBorder'}
              placeholder={t('placeHolderMunicipio')}
              showClearButton={true}
              name="selectMunicipio"
            />
          </Col>
          <Col md="6 mb-2">
            <Label>{t("address")}</Label>
            <input className="form-control btn-pill" name="city" type="text" placeholder={t("address")} autoComplete='off' onChange={e => setAddress(e.target.value)}  {...register('address', { required: true })} />
            <span>{errors.address && t("address")}</span>
            <div className="invalid-feedback">{"Ingrese una dirección por favor."}</div>
          </Col>
        </Row>
        <Button color="primary" type="submit" onClick={() => setValidateClass(true)}>{t("create")}</Button>
        <div className={loading ? 'loader-wrapper back' : 'loader-wrapper back loderhide'}><div className="loader-index">
          <span></span></div>
        </div>

      </Form>
    </Fragment>
  );
};

export default CreateFormEmployee;