import React, { useState, Fragment, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form'
import { Container, Row, Col, Form, Label, Input, Card, FormGroup, InputGroup, InputGroupText, CardHeader, Table, CardBody, Button, Modal, ModalHeader, ModalBody, ModalFooter, Popover, PopoverHeader, PopoverBody } from "reactstrap";
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { classes } from '../../data/layouts';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ReactModal from 'react-modal';

export default function PopupChangePass(
    {
        openPopupChangeP, setOpenPopupChangeP,
        employeesList,
        dataUser,
        setUsersList,
        error, setError
    }
) {

    //variables estandar
    const { register, handleSubmit, reset, formState: { errors } } = useForm();
    const [validateClass, setValidateClass] = useState(false);
    const infoUserLogin = JSON.parse(localStorage.getItem('infoUserLogin'));
    const navigate = useNavigate();
    const defaultLayoutObj = classes.find(item => Object.values(item).pop(1) === 'compact-wrapper');
    const layout = localStorage.getItem('layout') || Object.keys(defaultLayoutObj).pop();
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [textEmployee, setTextEmployee] = useState('');

    // New Pasword
    const [togglePassword, setTogglePassword] = useState(false);
    const [password, setPassword] = useState("");
    const [passConfirm, setPassConfirm] = useState("");
    const [popover, setPopover] = useState(false)
    const Toggle = () => setPopover(!popover);

    //Evento que sucede al dar clic al botón de crear
    const onSubmit: SubmitHandler<FormValues> = data => {
        updateUser(data);
    }

    const updateUser = (data) => {
        if (infoUserLogin !== null && infoUserLogin !== '') {
            if (dataUser.id !== undefined && dataUser.id !== '') {
                setLoading(true);

                const infoUpdate = {
                    password: data.password,
                    whoCreated: infoUserLogin.id
                };

                if (data.userName !== dataUser.userName) {
                    infoUpdate.userName = data.userName;
                }

                axios.put(`${process.env.REACT_APP_DOMAIN_SERVER}api/uptPassUser/${dataUser.id}`, infoUpdate)
                    .then((response) => {
                        toast.info(t('successUpdated'));
                        setValidateClass(false);
                        setLoading(false);
                        loadUsers();
                    })
                    .catch((errors) => {
                        setError(errors.response.data.messages)
                        console.log(errors);
                    });
            }
        } else {
            setTimeout(() => {
                toast.error(t('errorLogin'));
            }, 200);
        }
    }

    function loadUsers() {
        if (infoUserLogin !== null && infoUserLogin !== undefined) {
            axios
                .get(`${process.env.REACT_APP_DOMAIN_SERVER}api/getInfoUserByRol/${infoUserLogin.idRol}`)
                .then((res) => {
                    setUsersList(res.data.users);
                    setOpenPopupChangeP(false);

                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }

    function clearData(e) {
        setOpenPopupChangeP(!openPopupChangeP);
        setValidateClass(false);
        reset();
    }

    const onOpenModalP = () => {
        reset({
            userName: dataUser.userName,
            password: '',
            passConfirm: ''
        });
    }


    return (
        <Fragment>
            <ReactModal
                isOpen={openPopupChangeP}
                onAfterOpen={onOpenModalP}
                ariaHideApp={false}
                className={"backgroundModalReactContent"}
                overlayClassName={"backgroundModalReactOverlay"}
            >
                <Modal isOpen={openPopupChangeP} size="lg" centered>
                    <Form className={`needs-validation tooltip-validation ${validateClass ? 'validateClass' : ''}`} noValidate="" onSubmit={handleSubmit(onSubmit)}>
                        <ModalHeader>
                            {t("editInfo")}
                        </ModalHeader>
                        <ModalBody>
                            <Row>

                                <FormGroup>
                                    <Label className="col-form-label pt-0" >{t("employees")} </Label>
                                    <Autocomplete
                                        options={employeesList}
                                        disabled={true}
                                        getOptionLabel={(option) => option.firstName + ' ' + option.lastName}
                                        defaultValue={employeesList.find(v => v.id === dataUser.idEmployee)}
                                        classes={{ inputRoot: "form-control btn-pill" }}
                                        id="select-employees"
                                        renderInput={
                                            params => <TextField id="employeesUser"
                                                {...params} placeholder={t("placeholderEmployees")}
                                                InputProps={{ ...params.InputProps, disableUnderline: true }}
                                            />}
                                        inputValue={textEmployee}
                                        onInputChange={(event, newInputValue) => {
                                            setTextEmployee(newInputValue);
                                        }}
                                    />
                                </FormGroup>

                                <FormGroup>
                                    <Label className="col-form-label pt-0" >{t("userName")}</Label>
                                    <input className="form-control btn-pill" type="text" placeholder={t("placeholderUserName")}
                                        {...register('userName', {
                                            required: true
                                        })} />
                                    <span>{errors.userName && t("errorUserName")}</span>
                                </FormGroup>

                                {/* New Pasword */}
                                <FormGroup>
                                    <div className="position-relative">
                                        <Label className="col-form-label pt-0">{t("newPassword")}</Label>
                                        <input id={"spamError"} className="form-control btn-pill" type={togglePassword ? "text" : "password"} placeholder={t("placeholderPassword")} autoComplete="off" name="password" readOnly={false}  {...register('password', {
                                            required: true,
                                            autoComplete: "off",
                                            pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[ -/:-@\[-`{-~]).{6,64}$/
                                        })} onBlur={(e) => { setPassword(e.target.value) }} />
                                        <div className="show-hide" onClick={() => setTogglePassword(!togglePassword)}>
                                            <span style={{ width: "100px" }} className={togglePassword ? "" : "show"}></span>
                                        </div>
                                        <span>{(errors.password || error.password || (password.length < 6 && validateClass)) && t("errorPassword")}</span>
                                        <Popover
                                            placement={"bottom"}
                                            isOpen={popover}
                                            target={"spamError"}
                                            toggle={Toggle}
                                            trigger={"hover"}
                                            props={{
                                                style: { height: 'auto' },
                                            }}
                                        >
                                            <PopoverHeader style={{ backgroundColor: "#dc3545e6", color: '#fff' }}>{t("headPopPass")}</PopoverHeader>
                                            <PopoverBody style={{ color: "#dc3545e6" }}>
                                                {t("bodyPopPass1")}
                                                <br></br>
                                                {t("bodyPopPass2")}
                                                <br></br>
                                                {t("bodyPopPass3")}
                                                <br></br>
                                                {t("bodyPopPass4")}
                                            </PopoverBody>
                                        </Popover>
                                    </div>
                                </FormGroup>
                                <FormGroup>
                                    <div className="position-relative">
                                        <Label className="col-form-label pt-0">{t("confirmNewPassword")}</Label>
                                        <input className="form-control btn-pill" type={togglePassword ? "text" : "password"} autoComplete='new-password' placeholder={t("placeholderPassConfirm")} name="passConfirm"  {...register('passConfirm', { required: true })} onBlur={(e) => { setPassConfirm(e.target.value) }} />
                                        <div className="show-hide" onClick={() => setTogglePassword(!togglePassword)}><span className={togglePassword ? "" : "show"}></span></div>
                                        <span>{(password != passConfirm && validateClass) && t("errorMatchPassword")}</span>
                                    </div>
                                </FormGroup>
                            </Row>
                        </ModalBody>
                        <ModalFooter>
                            <Button type="button" color="secondary" onClick={clearData} >{t('close')}</Button>
                            <Button color="primary" type="submit" disabled={loading ? loading : loading} onClick={() => setValidateClass(true)} >{loading ? t("processing") : t('update')}</Button>
                        </ModalFooter>
                    </Form>
                    <div className={loading ? 'loader-wrapper back' : 'loader-wrapper back loderhide'}><div className="loader-index">
                        <span></span></div>
                    </div>

                </Modal>
            </ReactModal>
        </Fragment>
    );
}