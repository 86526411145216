import React, { useState, Fragment, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form'
import { Container, Row, Col, Form, Label, Input, Card, FormGroup, InputGroup, InputGroupText, CardHeader, Table, CardBody, Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import axios from "axios";
import { SelectBox } from 'devextreme-react/select-box';

export default function PopupChurches(
    {
        controlModal, setControlModal,
        dataChurches,
        isEdit,
        listStatus,
        setListChurches,
        statusChurch,
        setStatusChurch,
        readOnlyF
    }
) {

    const [loading, setLoading] = useState(false);

    // Get the information of the logged user
    const infoUserLogin = JSON.parse(localStorage.getItem('infoUserLogin'));

    // Class allow validate
    const { register, reset, handleSubmit, formState: { errors }, control } = useForm();

    const [validateClass, setValidateClass] = useState(false);

    //for errors setup
    const [error, setError] = useState({});

    // User translation
    const { t } = useTranslation();

    const onSubmit: SubmitHandler<FormValues> = data => {
        // If all validations are met we'll call register method
        if (isEdit) {
            updateChurch(data);
        } else {

            createChurch(data);
        }
    }

    useEffect(() => {
        setValidateClass(false);
        reset({
            churchName: dataChurches.churchName,
            churchCDI: dataChurches.churchCDI,
            statusChurch: dataChurches.statusChurch,
            addressChurch: dataChurches.addressChurch,
            phoneNumberChurch: dataChurches.phoneNumberChurch,
        });
    }, [controlModal])

    const handleStatusChurch = (newvalue) => {
        if (newvalue.value !== null && newvalue.value !== undefined) {
            console.log(newvalue.value.id);
            // Set the id
            setStatusChurch(newvalue.value.id);
        } else {
            // Clear the information of Status church
            setStatusChurch('');
        }
    }

    const changeStatusModalChurch = () => {
        setControlModal(!controlModal)
    };

    // Function that allow save a new record
    const createChurch = (data) => {
        if (infoUserLogin.id !== null && infoUserLogin.id !== '') {
            //validation empy fields
            setLoading(true);

            const infoCreate = {
                churchName: data.churchName,
                churchCDI: data.churchCDI,
                statusChurch: data.statusChurch,
                addressChurch: data.addressChurch,
                phoneNumberChurch: data.phoneNumberChurch,
                churchCodEs: data.churchCodEs,
                whoCreated: infoUserLogin.id
            };
            axios.post(`${process.env.REACT_APP_DOMAIN_SERVER}api/churches`, infoCreate)
                .then((response) => {

                    setValidateClass(false);
                    setLoading(false);

                    switch (response.data.error) {
                        case 0:
                            toast.info(t('successCreated'));

                            break;

                        default:
                            toast.error(response.data.error);

                            break;
                    }

                    // Load the list of brands
                    loadChurches();

                })
                .catch((errors) => {
                    setError(errors.response.data.messages)
                    console.log(errors);
                });
        } else {
            setTimeout(() => {
                toast.error(t('errorLogin'));
            }, 200);
        }
    };
    // Function that allow update the record
    const updateChurch = (data) => {
        if (infoUserLogin.id !== null && infoUserLogin.id !== '' && dataChurches.IdChurch !== undefined) {
            //validation empy fields
            if (statusChurch !== undefined && statusChurch !== '') {
                setLoading(true);

                const infoUpdate = {
                    churchName: data.churchName,
                    churchCDI: data.churchCDI,
                    churchStatus: statusChurch,
                    addressChurch: data.addressChurch,
                    phoneNumberChurch: data.phoneNumberChurch,
                    churchCodEs: data.churchCodEs,
                    whodidit: infoUserLogin.id
                };
                axios.put(`${process.env.REACT_APP_DOMAIN_SERVER}api/churches/${dataChurches.IdChurch}`, infoUpdate)
                    .then((response) => {
                        setValidateClass(false);
                        setLoading(false);

                        toast.info(t('successUpdated'));
                        loadChurches();

                    })
                    .catch((errors) => {
                        setError(errors.response.data.messages)
                        console.log(errors);
                    });
            }
        } else {
            setTimeout(() => {
                toast.error(t('errorLogin'));
            }, 200);
        }

    };


    //function to reload the subcategories
    function loadChurches() {

        if (infoUserLogin !== null && infoUserLogin !== undefined) {

            axios
                .get(`${process.env.REACT_APP_DOMAIN_SERVER}api/getChcById/${infoUserLogin.IdChurch}`)
                .then((response) => {

                    setListChurches(response.data.churches);
                    setControlModal(!controlModal);

                })
                .catch((error) => {
                    console.log(error);
                });
        }

    }


    return (
        <Fragment>
            <Modal
                size="lg" isOpen={controlModal} centered>
                <Form className={`needs-validation tooltip-validation ${validateClass ? 'validateClass' : ''}`} noValidate="" onSubmit={handleSubmit(onSubmit)}>

                    <ModalHeader toggle={changeStatusModalChurch}>
                        {isEdit ? t("editInfo") : t("createInfo")}
                    </ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col md="6 mb-2">
                                <Label>{t("name")}</Label>
                                <input className="form-control btn-pill" type="text" defaultValue={dataChurches.churchName} placeholder={t("placeHolderChurchName")} {...register('churchName', { required: true })} />
                                <span>{errors.churchName && t("errorRequired")}</span>
                            </Col>
                            <Col md="6 mb-2">
                                <Label>{t("churchCDI")}</Label>
                                <input className="form-control btn-pill" type="text" defaultValue={dataChurches.churchCDI} placeholder={t("pHCDIName")} {...register('churchCDI', { required: true })} />
                                <span>{errors.churchCDI && t("errorRequired")}</span>
                            </Col>
                            <Col md="6 mb-2">
                                <Label>{t("churchCod")}</Label>
                                <input className="form-control btn-pill" type="text" defaultValue={dataChurches.churchCodEs} placeholder={t("churchCod")} {...register('churchCodEs', { required: true })} />
                                <span>{errors.churchCodEs && t("errorRequired")}</span>
                            </Col>

                            <Col md="6 mb-2">
                                <Label>{t("phoneNumber")}</Label>
                                <input className="form-control btn-pill" type="text" defaultValue={dataChurches.phoneNumberChurch} placeholder={t("placeHolderPhoneNumber")} {...register('phoneNumberChurch', { required: true })} />
                                <span>{errors.phoneNumberChurch && t("errorRequired")}</span>
                            </Col>
                            <Col md="6 mb-2">
                                <Label>{t("address")}</Label>
                                <input className="form-control btn-pill" type="text" defaultValue={dataChurches.addressChurch} placeholder={t("placeHolderAddress")} {...register('addressChurch', { required: true })} />
                                <span>{errors.addressChurch && t("errorRequired")}</span>
                            </Col>
                            {isEdit ?
                                <Col md="6 mb-2">
                                    <Label>{t("selectStatus")}</Label>
                                    <SelectBox
                                        dataSource={listStatus}
                                        displayExpr="name"
                                        value={listStatus.find(v => v.id === statusChurch)}
                                        searchEnabled={true}
                                        className={'form-control dxSelectBorder'}
                                        placeholder={t('selectStatus')}
                                        showClearButton={true}
                                        name="selectStatus"
                                        onValueChanged={handleStatusChurch}
                                        readOnly={readOnlyF}
                                    />
                                    <input type="hidden" />
                                    <span>{((statusChurch === '' || statusChurch === undefined) && validateClass) && t("errorRequired")}</span>
                                </Col>
                                : ''
                            }
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={changeStatusModalChurch} >{t('close')}</Button>
                        <Button color="primary" type="submit" disabled={loading ? loading : loading} onClick={() => setValidateClass(true)} >{loading ? t("processing") : isEdit ? t('update') : t('create')}</Button>
                    </ModalFooter>
                </Form>

                <div className={loading ? 'loader-wrapper back' : 'loader-wrapper back loderhide'}><div className="loader-index">
                    <span></span></div>
                </div>

            </Modal>
        </Fragment>
    );
}
