import React from 'react';
import CatalogList from './catalogList';
import CatalogListComplete from './catalogListComplete';


class CatalogItem extends React.PureComponent {
  render() {
    const catalogInfo = this.props.data;
    return (
      <div>
        <div className="tabpanel-item">
            
                {
                    catalogInfo.ID==1 ?(
                        <CatalogList></CatalogList>
                    ):catalogInfo.ID==2 ?( <CatalogListComplete></CatalogListComplete> ) :<h2>Nothing to show</h2>
                }
             {/* <h1>Hello {catalogInfo.CompanyName}</h1> */}
          <div>
          </div>
          <div>
            
          </div>
        </div>
      </div>
    );
  }
}

export default CatalogItem;
