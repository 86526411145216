import React, { useState, Fragment, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form'
import { Container, Row, Col, Form, Label, Input, Card, FormGroup, InputGroup, InputGroupText, CardHeader, Table, CardBody, Button, Modal, ModalHeader, ModalBody, ModalFooter, Alert } from "reactstrap";
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import axios from "axios";
import { SelectBox } from 'devextreme-react/select-box';
import RadioGroup from 'devextreme-react/radio-group';

import moment from 'moment';
import DateBox from 'devextreme-react/date-box';
import { Edit } from 'react-feather';
import swal from 'sweetalert';


export default function PopupReplenishment(
    {
        controlModal,
        setControlModal,
        dataPettyCashActive,
        isEdit,
        setListDetailPettyCashActive,
        t,
        movementType,
        setMovementType,
        setAccountName,
        accountName,
        catalogComplete,
        existsAcc,
        setExistsAcc,
        existActivePettyCash,
        dataSystemExpect

    }
) {

    const [loading, setLoading] = useState(false);

    // Get the information of the logged user
    const infoUserLogin = JSON.parse(localStorage.getItem('infoUserLogin'));

    // Class allow validate
    const { register, reset, handleSubmit, formState: { errors }, control } = useForm();

    const [validateClass, setValidateClass] = useState(false);

    const [dateF, setDateF] = useState(moment().format('YYYY-MM-DD'));

    // Id classification  1: Gastos CDSP, 2: Gastos CSP,  3: Ninguno
    const [IdClassification, setIdClassification] = useState(3);

    const movementTypeArray = [
        { id: 1, text: t('income') },
        { id: 2, text: t('expense') },
    ];

    //for errors setup
    const [error, setError] = useState({});


    const onSubmit: SubmitHandler<FormValues> = data => {
        // If all validations are met we'll call register method
        if (infoUserLogin != null && infoUserLogin !== '' && infoUserLogin !== undefined) {
            if (isEdit) {
                updatePettyReplenishment(data);
            } else {
                if (movementType == 2) {
                    validateAmount(data);
                }else{
                    createReplenishment(data);
                }
            }
        }

    }

    useEffect(() => {
        setValidateClass(false);
        reset({
            dateDetailPettyCash: dataPettyCashActive.dateDetailPettyCash,
            documentNumber: dataPettyCashActive.documentNumber,
            accountNumber: dataPettyCashActive.accountNumber,
            descriptionDetail: dataPettyCashActive.descriptionDetail,
            amount: dataPettyCashActive.amount,
        });

    }, [controlModal])


    const changeStatusModal = () => {
        setControlModal(!controlModal)
    };

    // Function that allow save a new record
    const createReplenishment = (data) => {

        if (infoUserLogin.id !== null && infoUserLogin.id !== '') {

            if (dateF !== '') {
                if (existsAcc == true) {
                    //validation empy fields

                    if (movementType == 1 && dataSystemExpect.lastDateDetail != '' && dataSystemExpect.lastDateDetail != null) {
                        let dateIncome = moment(dateF);
                        let dateLastRecord = moment(dataSystemExpect.lastDateDetail);
                        if (dateIncome.isBefore(dateLastRecord)) {
                            toast.error("La fecha del ingreso no puede ser menor que la última fecha que se registró un gasto");
                            return false;
                        }
                    }

                    setLoading(true);

                    const infoCreate = {
                        dateDetailPettyCash: dateF,
                        documentNumber: data.documentNumber,
                        accountNumber: data.accountNumber,
                        descriptionDetail: data.description,
                        amount: data.amount,
                        movementType: movementType,
                        balance: 0,
                        accountName: accountName,
                        whoCreated: infoUserLogin.id,
                        IdChurch: infoUserLogin.IdChurch,
                        IdClassification: IdClassification
                    };

                    axios.post(`${process.env.REACT_APP_DOMAIN_SERVER}api/PettyCashCtr`, infoCreate)
                        .then((response) => {
                            setLoading(false);

                            switch (response.data.error) {
                                case 1:
                                    //  It could not insert into petty cash
                                    toast.error("No se pudo guardar la información de la caja chica");

                                    break;
                                case 2:
                                    //  It was expecting an income not an expense
                                    toast.error("Se espera un ingreso no un egreso");

                                    break;
                                case 3:
                                    //  It was expecting an expense not an income
                                    toast.error("Se espera un egreso no un ingreso");

                                    break;
                                case 4:
                                    // It could not insert int detail petty cash
                                    toast.error("No se logró guardar la información en el detalle de la caja chica");

                                    break;
                                case 5:
                                    // It could not insert the first time an expense
                                    toast.error("La primera vez debe hacer un ingreso del monto de su caja chica");

                                    break;
                                default:
                                    setValidateClass(false);
                                    toast.info(t('successCreated'));
                                    existActivePettyCash();
                                    loadDetailPettyCash();
                                    break;
                            }



                        })
                        .catch((errors) => {
                            setError(errors.response.data.messages)
                            console.log(errors);
                        });
                } else {
                    toast.error("El número de cuenta no existe, por favor revise la información");
                }


            } else {
                toast.error("La fecha no puede ser vacía");
            }

        } else {
            setTimeout(() => {
                toast.error(t('errorLogin'));
            }, 200);
        }
    };

    // Function that allow update the record
    const updatePettyReplenishment = (data) => {
        if (infoUserLogin.id !== null && infoUserLogin.id !== '') {
            //validation empy fields
            if (dateF !== '') {
                if (existsAcc == true) {

                    if (dataPettyCashActive.IdDetailPettyCash !== null && dataPettyCashActive.IdDetailPettyCash !== undefined) {

                        setLoading(true);

                        const infoUpdate = {
                            dateDetailPettyCash: dateF,
                            documentNumber: data.documentNumber,
                            accountNumber: data.accountNumber,
                            descriptionDetail: data.description,
                            amount: data.amount,
                            movementType: movementType,
                            accountName: accountName,
                            whodidit: infoUserLogin.id,
                            IdChurch: infoUserLogin.IdChurch,
                            IdClassification: IdClassification

                        };

                        axios.put(`${process.env.REACT_APP_DOMAIN_SERVER}api/PettyCashCtr/${dataPettyCashActive.IdDetailPettyCash}`, infoUpdate)
                            .then((response) => {

                                switch (response.data.error) {
                                    case 1:
                                        //  It could not update because there isn't petty cash
                                        toast.error("No se encuentran datos para realizar la operación");

                                        break;
                                    case 2:
                                        //  It couldn't updated info from detail petty cash
                                        toast.error("No se pudo actualizar la información del detalle de caja chica");

                                        break;
                                    case 3:
                                        //  The balance could not be negative
                                        toast.error("El balance no puede ser negativo");

                                        break;
                                    default:
                                        setValidateClass(false);
                                        setLoading(false);
                                        toast.info(t('successUpdated'));
                                        existActivePettyCash();
                                        loadDetailPettyCash();
                                        break;
                                }

                            })
                            .catch((errors) => {
                                setError(errors.response.data.messages)
                                console.log(errors);
                            });
                    } else {
                        toast.error("No se encontró detalle de registro a modificar");
                    }


                } else {
                    toast.error("El número de cuenta no existe, por favor revise la información");
                }


            } else {
                toast.error("La fecha no puede ser vacía");
            }
        } else {
            setTimeout(() => {
                toast.error(t('errorLogin'));
            }, 200);
        }

    };


    //Function that allow to load info detail petty cash
    function loadDetailPettyCash() {

        if (infoUserLogin !== null && infoUserLogin !== undefined) {

            axios
                .get(`${process.env.REACT_APP_DOMAIN_SERVER}api/getDetailPettyCashActive/${infoUserLogin.IdChurch}`)
                .then((response) => {
                    setListDetailPettyCashActive(response.data.detailPettyCash);
                    setControlModal(!controlModal);
                })
                .catch((error) => {
                    console.log(error);
                });

        }
    }

    const onValueChangedDate = (event) => {
        let dateSelected = '';
        if (event.value !== null) {
            dateSelected = moment(event.value).format('YYYY-MM-DD');
        }
        setDateF(dateSelected);
    }

    const changeSelectionRb = (event) => {
        setMovementType(event.value);
    }

    // Function that allow to manage the information when the user type an account number
    const onChangeAccountNumber = (event) => {
        // Validate if there is an user logged
        if (infoUserLogin !== null && infoUserLogin !== undefined) {

            // If the account number is not empty

            if (event !== "") {

                // Look for the account name

                let foundKey = catalogComplete.find(val => {
                    return val.accountNumber == event;
                })

                //  If there is information

                if (foundKey !== undefined && foundKey !== null && foundKey !== "") {

                    if (foundKey.isEditable == 1) {

                        //    Show the account name in the span tag

                        setAccountName(foundKey.accountName);
                        setIdClassification((foundKey.IdClassification != null && foundKey.IdClassification != '') ? foundKey.IdClassification : 3);
                        setExistsAcc(true);

                    } else {

                        setAccountName("CUENTA ENCONTRADA PERO NO EDITABLE");
                        setExistsAcc(false);
                        setIdClassification(3);

                    }



                } else {

                    setAccountName("CUENTA NO ENCONTRADA");
                    setExistsAcc(false);
                    setIdClassification(3);
                }

            } else {
                setAccountName("");
                setExistsAcc(false);
                setIdClassification(3);

            }

        }

    }



    async function validateAmount(data) {
        try {
            const response = await axios.get(`${process.env.REACT_APP_DOMAIN_SERVER}api/verifyAmountValid/${infoUserLogin.IdChurch}/${data.amount}`);

            switch (response.data.error) {
                case 0:
                    createReplenishment(data);
                    break;
                case 1:
                    // It could not update because there isn't petty cash
                    swal("¡Atención!", "El monto del gasto no puede ser superior a $" + response.data.amountAvailable, "warning");
                    break;

                default:
                    swal("¡Atención!", response.data.message, "warning");
                    
                    break;
            }
        } catch (error) {
            console.error('Error validating amount:', error);
            // Handle the error, display an error message, or perform other error handling logic as needed.
        }
    }




    return (
        <Fragment>
            <Modal
                size="lg" isOpen={controlModal} centered>
                <Form className={`needs-validation tooltip-validation ${validateClass ? 'validateClass' : ''}`} noValidate="" onSubmit={handleSubmit(onSubmit)}>

                    <ModalHeader toggle={changeStatusModal}>
                        {isEdit ? t("editInfo") : t("createInfo")}
                    </ModalHeader>
                    <ModalBody>
                        <Row>

                            <Col md="12 mb-1">
                                {
                                    dataSystemExpect.hasOwnProperty('descrip') > 0 ? (
                                        <Alert color="light">
                                            <p>{dataSystemExpect.descrip}  <b>{dataSystemExpect.complementText}</b></p>
                                        </Alert>
                                    ) : ''
                                }


                            </Col>
                            <Col md="6 mb-2">
                                <Label>{t("dateF")}</Label>
                                {
                                    movementType == 1 ? (
                                        <DateBox
                                            defaultValue={dataPettyCashActive.dateDetailPettyCash}
                                            type="date"
                                            displayFormat="dd/MM/yyyy"
                                            className={'form-control dxSelectBorder'}
                                            onValueChanged={onValueChangedDate}
                                            showClearButton={true}
                                            max={new Date()}
                                            min={dataSystemExpect.lastDateDetail}
                                        />
                                    ) : (
                                        <DateBox
                                            defaultValue={dataPettyCashActive.dateDetailPettyCash}
                                            type="date"
                                            displayFormat="dd/MM/yyyy"
                                            className={'form-control dxSelectBorder'}
                                            onValueChanged={onValueChangedDate}
                                            showClearButton={true}
                                            max={new Date()}
                                            min={null}
                                        />
                                    )
                                }

                                <input type="hidden" />
                                <span>{((dateF === '' || dateF === undefined) && validateClass) && t("errorRequired")}</span>
                            </Col>
                            <Col md="6 mb-2">
                                <Label>{t("documentNumber")}</Label>
                                <input className="form-control btn-pill" type="text" defaultValue={dataPettyCashActive.documentNumber} placeholder={t("documentNumber")} {...register('documentNumber', { required: true })} />
                                <span>{errors.documentNumber && t("errorRequired")}</span>
                            </Col>

                            <Col md="6 mb-2">
                                <Label>{t("accountNumber")}</Label>
                                <input className="form-control btn-pill" autoComplete='false' type="text" defaultValue={dataPettyCashActive.accountNumber} placeholder={t("accountNumber")}  {...register('accountNumber', { required: true, onChange: (e) => onChangeAccountNumber(e.target.value) })} />
                                <br />
                                {
                                    existsAcc ? (
                                        <span style={{ 'backgroundColor': '#4433FF', 'color': 'white', 'borderRadius': '30px' }}>{accountName}</span>

                                    ) : (
                                        <span style={{ 'backgroundColor': '#DC231B', 'color': 'white', 'borderRadius': '30px' }}>{accountName}</span>

                                    )
                                }

                                <span>{errors.accountNumber && t("errorRequired")}</span>
                            </Col>
                            <Col md="6 mb-2">
                                <Label>{t("amountO")}</Label>
                                {
                                    movementType == 1 ?
                                        (
                                            <input className="form-control btn-pill" type="number" step="0.01" min={0} defaultValue={dataPettyCashActive.amount} placeholder={t("amountO")} {...register('amount', { required: true })} />

                                        ) :
                                        (
                                            <input className="form-control btn-pill" type="number" step="0.01" min={0} max={parseInt(dataSystemExpect.limitReceipt)} defaultValue={dataPettyCashActive.amount} placeholder={t("amountO")} {...register('amount', { required: true })} />

                                        )

                                }
                                <span>{errors.amount && t("errorRequired")}</span>
                            </Col>
                            <Col md="12 mb-1">
                                <Label>{t("description")}</Label>
                                <input className="form-control btn-pill" type="text" defaultValue={dataPettyCashActive.descriptionDetail} placeholder={t("placeHolderDescription")} {...register('description', { required: true })} />
                                <span>{errors.description && t("errorRequired")}</span>
                            </Col>
                            {
                                isEdit !== true ? (
                                    <Col md="6 mb-2">
                                        <Label>{t("movementType")}</Label>
                                        <RadioGroup
                                            id="radio-group-with-selection"
                                            items={movementTypeArray}
                                            defaultValue={movementTypeArray.find(v => v.id == movementType).id}
                                            valueExpr="id"
                                            displayExpr="text"
                                            layout="horizontal"
                                            onValueChanged={changeSelectionRb}
                                        />
                                    </Col>
                                ) : ''
                            }


                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={changeStatusModal} >{t('close')}</Button>
                        <Button color="primary" type="submit" disabled={loading ? loading : loading} onClick={() => setValidateClass(true)} >{loading ? t("processing") : isEdit ? t('update') : t('create')}</Button>
                    </ModalFooter>
                </Form>

                <div className={loading ? 'loader-wrapper back' : 'loader-wrapper back loderhide'}><div className="loader-index">
                    <span></span></div>
                </div>

            </Modal>
        </Fragment>
    );
}
