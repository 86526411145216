import React, { Fragment, useState, useEffect } from "react";
import Breadcrumb from "../../layout/breadcrumb";
import { Container, Row, Col, Card, CardHeader, Table, CardBody, Button, Modal, ModalHeader, ModalBody, ModalFooter, Label } from "reactstrap";
import axios from "axios";
import 'devextreme/dist/css/dx.material.teal.light.css';
import PopupCatalogF from "./popupCatalogF";
import DetailTemplateFCat2 from './DetailTemplateFCat2';

import DataGrid, { Column, Editing, Popup, Paging, Lookup, Form, SearchPanel, Scrolling, Pager, Export, HeaderFilter, RequiredRule, MasterDetail } from 'devextreme-react/data-grid';
import 'devextreme-react/text-area';
import { useTranslation } from 'react-i18next';
import { SelectBox } from "devextreme-react";

const CatalogListComplete = () => {

  // Get the information of user

  const infoUserLogin = JSON.parse(localStorage.getItem('infoUserLogin'));

  // To traslate the words
  const { t } = useTranslation();

  // Spaces
  const tab = '\u00A0';

  // IdChurch of user logged
  const [idChurchUserLogged, setIdChurchUserLogged] = useState('');

  const [readOnlyForm, setReadOnlyForm] = useState(false);

  // To get the list information of the menu
  const [catalogComplete, setCatalogComplete] = useState([]);
  const [permissionsModule, setPermissionsModule] = useState([]);

  // To get the information of the menu to edit
  const [dataChurches, setDataChurches] = useState([]);


  // Use effect is launch one time when the page load
  useEffect(() => {

    // Get list of church 
    axios
      .get(`${process.env.REACT_APP_DOMAIN_SERVER}api/churches`)
      .then((response) => {
        setDataChurches(response.data.churches);
      })
      .catch((error) => {
        console.log(error);
      });


    if (infoUserLogin !== null && infoUserLogin !== undefined) {

      getCatalogListComplete();

      // Get the information of the church of the user belongs
      axios
        .get(`${process.env.REACT_APP_DOMAIN_SERVER}api/getInforChurch/${infoUserLogin.idRol}`)
        .then((response) => {
          setIdChurchUserLogged(response.data.infoChurch[0].IdChurch);
        })
        .catch((error) => {
          console.log(error);
        });
      // To determinate if the user logged is Admin
      setReadOnlyForm(infoUserLogin.idRol == 1 ? false : true);
    }

  }, []);

  useEffect(() => {
    // Get general permissions
    if (infoUserLogin !== null) {
      if (infoUserLogin.id !== null && infoUserLogin.id !== '') {
        axios
          .get(`${process.env.REACT_APP_DOMAIN_SERVER}/api/getPermission/${infoUserLogin.idRol}`)
          .then((result) => {
            setPermissionsModule(result.data.response);
          })
          .catch((error) => {
            console.log(error);
          });

      } else {
        setPermissionsModule([]);
      }
    } else {
      setPermissionsModule([]);

    }

  }, []);


  // Function to allow to get catalog first
  function getCatalogListComplete() {
    axios
      .get(`${process.env.REACT_APP_DOMAIN_SERVER}api/geCtgCmp/${infoUserLogin.idRol}`)
      .then((response) => {
        setCatalogComplete(response.data.catalogComp);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const handleChangeChurch = (e) => {
    if (e.value !== null) {
      axios
        .get(`${process.env.REACT_APP_DOMAIN_SERVER}api/geCtgCmpCh/${e.value.IdChurch}`)
        .then((response) => {
          setCatalogComplete(response.data.catalogComp);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      setCatalogComplete([]);
    }
  }


  return (
    <Fragment>
      <Breadcrumb parent="Catalog" title={t("accountList")} />
      <Container fluid={true}>
        <Row className="justify-content-md-center">
          <Col sm="12">

            {
              permissionsModule.find(v => { return v.functionName === 'filterChurchAdmin' }) ?
                (
                  <Col md="6 mb-2" >
                    <Label className="nameFunction" style={{ fontSize: '20px' }}>{t("selectChurch")}:</Label>
                    <SelectBox
                      dataSource={dataChurches}
                      displayExpr="churchName"
                      onValueChanged={handleChangeChurch}
                      searchEnabled={true}
                      className={'form-control dxSelectBorder'}
                      placeholder={t('selectChurch')}
                      showClearButton={true}
                      name="selectChurch"
                    />
                    <br /><br /><br />
                  </Col>
                ) :
                ''
            }


            <Card>
              <CardBody>
                <Row >
                  <Col sm="12" lg="12" xl="12">
                    <div className="table-responsive">
                      <div id="data-grid-demo" className="table-primary">

                        <div className="btn-showcase ">
                          <Button className="btn-pill" color="primary" ><i className="icofont icofont-ui-add"></i>{tab + tab}{t('create')}</Button>
                        </div>

                        <DataGrid id="grid-container"
                          dataSource={catalogComplete}
                          // keyExpr="IdCatFirstLevel"
                          showBorders={true}
                          rowAlternationEnabled={true}
                          columnAutoWidth={true}
                          t={t}
                        >

                          <HeaderFilter visible={true} allowSearch={true} />
                          <Export enabled={true} />
                          <SearchPanel visible={true} highlightCaseSensitive={true} width={350} />
                          <Scrolling
                            useNative={false}
                            scrollByContent={true}
                            scrollByThumb={true}
                            showScrollbar="onHover" />
                          <Paging defaultPageSize={10} />
                          <Pager
                            showPageSizeSelector={true}
                          />

                          {/* <Column dataField="churchName" caption={t('church')} ></Column> */}
                          <Column dataField="accountNumber" caption={t('accountNumber')} ></Column>
                          <Column dataField="accountName" caption={t('accountName')} >
                            <RequiredRule />
                          </Column>
                          <Column dataField="accountNameType" caption={t('accountType')} >
                            <RequiredRule />
                          </Column>
                          <Column dataField="status" caption={t("selectStatus")} >
                          </Column>
                        </DataGrid>

                      </div>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );

};

export default CatalogListComplete;
