import React, { Fragment, useState, useEffect } from "react";
import Breadcrumb from "../../layout/breadcrumb";
import { Container, Row, Col, Card, CardHeader, Table, CardBody, Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import axios from "axios";
import 'devextreme/dist/css/dx.material.teal.light.css';
import PopupCatalogF from "./popupCatalogF";
import DetailTemplateFCat2 from './DetailTemplateFCat2';

import DataGrid, { Column, Editing, Popup, Paging, Lookup, Form, SearchPanel, Scrolling, Pager, Export, HeaderFilter, RequiredRule, MasterDetail } from 'devextreme-react/data-grid';
import 'devextreme-react/text-area';
import { useTranslation } from 'react-i18next';

import TabPanel from 'devextreme-react/tab-panel';
import CatalogItem from "./catalogItem";

const MainPageCatalog = () => {

    // Get the information of user

    const infoUserLogin = JSON.parse(localStorage.getItem('infoUserLogin'));

    // To traslate the words
    const { t } = useTranslation();

    const multiViewItems = [{
        ID: 1,
        CompanyName: 'Manage Catalog',
        Description: 'Allow to create and update information',
    }];

    multiViewItems.push({
        ID: 2,
        CompanyName: 'List Catalog',
        Description: 'Allow to create and update information',
    });



    const [selectedIndex, setSelectedIndex] = useState(0);
    const [selectedItem, setSelectedItem] = useState(multiViewItems[0]);

    const [loop, setLoop] = useState(false);
    const [animationEnabled, setAnimationEnabled] = useState(true);
    const [swipeEnabled, setSwipeEnabled] = useState(true);


 

 

    const onSelectionChanged = (args) => {
    
        console.log(args);
    }


    const itemTitleRender = (company) => {
        return <span>{company.CompanyName}</span>;
    }



    return (
        <Fragment>
            <Breadcrumb parent="Roles" title={t("catalogTitle")} />
            <Container fluid={true}>
                <Row className="justify-content-md-center">
                    <Col sm="12">
                        <Card>
                            <CardBody>
                                <Row >
                                    <Col sm="12" lg="12" xl="12">
                                        <div>
                                            <TabPanel
                                                // height={600}
                                                dataSource={multiViewItems}
                                                // selectedItem={selectedItem}
                                                onOptionChanged={onSelectionChanged}
                                                loop={loop}
                                                itemTitleRender={itemTitleRender}
                                                itemComponent={CatalogItem}
                                                animationEnabled={animationEnabled}
                                                swipeEnabled={swipeEnabled}
                                                // deferRendering={false}
                                                hoverStateEnabled={false}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );

};

export default MainPageCatalog;
