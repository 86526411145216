import React, { Fragment, useState, useEffect } from "react";
import Breadcrumb from "../../layout/breadcrumb";
import { Container, Row, Col, Card, CardHeader, Table, CardBody, Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, Tooltip } from "reactstrap";
import axios from "axios";
import 'devextreme/dist/css/dx.material.teal.light.css';
import PopupDeatilSale from "./popupDetailSale";
import DataGrid, { Column, Editing, Popup, Paging, Lookup, Form, SearchPanel, Scrolling, Pager, Export, HeaderFilter, RequiredRule, Summary, TotalItem, } from 'devextreme-react/data-grid';
import 'devextreme-react/text-area';
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import { classes } from '../../data/layouts';
import DateBox from 'devextreme-react/date-box';
import { toast } from "react-toastify";
import moment from "moment/moment";
import SweetAlert from 'sweetalert2'

const SalesList = () => {
    // To traslate the words
    const { t } = useTranslation();

    //To navigate to other pages
    const navigate = useNavigate();

    //defaults values for layout
    const defaultLayoutObj = classes.find(item => Object.values(item).pop(1) === 'compact-wrapper');
    const layout = localStorage.getItem('layout') || Object.keys(defaultLayoutObj).pop();
    // Get the information of the logged user
    const infoUserLogin = JSON.parse(localStorage.getItem('infoUserLogin'));
    const [permissionsModule, setPermissionsModule] = useState([]);
    // Spaces
    const tab = '\u00A0';

    //global vars
    const symbol = "$";
    const [loading, setLoading] = useState(false);

    // To get the information of the sub categories
    const [dataSale, setDataSale] = useState([]);

    // To get the information of the categories
    const [listSales, setListSales] = useState([]);

    // To get the information of the categories
    const [listClients, setListClients] = useState([]);

    // To get the list of status
    const [listStatus, setListStatus] = useState([]);

    //modal data
    const [controlModal, setControlModal] = useState(false);
    const [saleData, setSaleData] = useState([]);
    const [beginingDate, setBeginingDate] = useState(null);
    const [endingDate, setEndingDate] = useState(null);



    // Use effect is launch one time when the page load
    useEffect(() => {
        setLoading(true);
        loadInformation();

    }, []);

    function loadInformation() {
        let infoSale = {
            bDate: "",
            eDate: "",
            idUser: 0
        };

        infoSale = JSON.stringify(infoSale);
        // Get the list of orders by status and user
        axios.get(`${process.env.REACT_APP_DOMAIN_SERVER}api/getSalesByParams/${infoSale}`)
            .then((response) => {
                setLoading(false);

                setListSales(response.data.Sales);
            }).catch((error) => {
                console.log(error);
            });
    }

    // Get the list of status only load once time
    useEffect(() => {
        // We pass like parameter 2 because 2 has the status for sales
        axios.get(`${process.env.REACT_APP_DOMAIN_SERVER}/api/processState/${2}`)
            .then((response) => {
                setListStatus(response.data.listStatus);
            }).catch((error) => {
                console.log(error);
            });
    }, []);



    useEffect(() => {
        // Get general permissions

        if (infoUserLogin !== null) {
            if (infoUserLogin.id !== null && infoUserLogin.id !== '') {
                axios
                    .get(`${process.env.REACT_APP_DOMAIN_SERVER}/api/getPermission/${infoUserLogin.idRol}`)
                    .then((result) => {
                        setPermissionsModule(result.data.response);
                    })
                    .catch((error) => {
                        console.log(error);
                    });

            } else {
                setPermissionsModule([]);
            }
        } else {
            setPermissionsModule([]);

        }

    }, []);


    // Alert to cancel a sale
    const cancelSale = (info) => {

        SweetAlert.fire({
            title: t("titleAlertD"),
            text: t("descripAlertInvoice"),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Ok',
            cancelButtonText: 'cancel',
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                // Update information

                axios
                    .put(`${process.env.REACT_APP_DOMAIN_SERVER}api/sales/${info.id}`)
                    .then((response) => {

                        console.log(response);
                        loadInformation();

                        SweetAlert.fire(
                            'Canceled!',
                            t("msgSale"),
                            'success'
                        );

                    })
                    .catch((error) => {
                        console.log(error);
                    });


            }
            else {
                SweetAlert.fire(
                    t("msgRejectDeleted")
                )
            }
        })
    }

    // Function that allow create an toltip element
    const TooltipHtmlElementItem = props => {
        const { item } = props;
        const [tooltip, setTooltip] = useState(false)
        const HtmlelElementToggle = () => setTooltip(!tooltip);


        const [tooltipDelete, setTooltipDelete] = useState(false)
        const HtmlelElementToggleDelete = () => setTooltipDelete(!tooltipDelete);
        return (
            <>
                <Tooltip
                    placement={"top"}
                    isOpen={tooltip}
                    target={"Tooltip-seeDetail-" + item.id}
                    toggle={HtmlelElementToggle}
                >
                    {t("seeDetail")}
                </Tooltip>
                {
                    permissionsModule.find(v => { return v.functionName === 'Cancel_sale' }) ?
                        (
                            <Tooltip
                                placement={"top"}
                                isOpen={tooltipDelete}
                                target={"Tooltip-cancel-" + item.id}
                                toggle={HtmlelElementToggleDelete}
                            >
                                {t("cancelSale")}
                            </Tooltip>

                        ) :
                        ''
                }



            </>
        );
    };


    const cellRenderAction = (data) => {
        return <div align="center">
            <i style={{ cursor: 'pointer' }} className="fa fa-eye fa-2x" id={"Tooltip-seeDetail-" + data.data.id} onClick={() => editSale(data)} />
            {
                permissionsModule.find(v => { return v.functionName === 'Cancel_sale' }) ?
                    (
                        <i style={{ cursor: 'pointer', marginLeft: '10px' }} id={"Tooltip-cancel-" + data.data.id} className="fa fa-times fa-2x" onClick={() => cancelSale(data.data)} />

                    ) :
                    ''
            }
            <TooltipHtmlElementItem key={data.data.id} item={data.data} />
        </div>;
    }

    const createSale = (e) => {
        navigate(`${process.env.PUBLIC_URL}/app/sales/createSale/${layout}`);
    };

    const editSale = (e) => {
        setSaleData(e.data);
        setControlModal(!controlModal);
    };

    const getSalesByDate = () => {

        if (beginingDate !== null && endingDate !== null) {

            let infoSale = {
                bDate: moment(beginingDate).format("YYYY-MM-DD"),
                eDate: moment(endingDate).format("YYYY-MM-DD"),
                idUser: 0
            };

            infoSale = JSON.stringify(infoSale);
            // Get the list of orders by status and user
            axios.get(`${process.env.REACT_APP_DOMAIN_SERVER}api/getSalesByParams/${infoSale}`)
                .then((response) => {
                    setListSales(response.data.Sales);
                }).catch((error) => {
                    console.log(error);
                });


        } else {
            toast.warning(t("errorDates"));
        }

    }

    const rowRenderMethod = (e) => {

       
        return (
            <>
                {
                    // If the sales it has been canceled
                    e.data.status === '3' ?

                        (
                            <tr style={{ textAlign: "center", background: "#EEECFF" }} >

                                <td >
                                    <div align="center">
                                        <i style={{ cursor: 'pointer' }} className="fa fa-eye fa-2x" id={"Tooltip-seeDetail-" + e.data.id} onClick={() => editSale(e)} />
                                    </div>
                                </td>
                                <td>{e.data.customerName}</td>
                                <td>{e.data.total}</td>
                                <td>{moment(e.data.createDate).format("DD/MM/YYYY")}</td>
                                <td>{e.data.userName}</td>
                            </tr>
                        ) :
                        (

                            <tr style={{ textAlign: "center" }} >

                                <td >
                                    <div align="center">
                                        <i style={{ cursor: 'pointer' }} className="fa fa-eye fa-2x" id={"Tooltip-seeDetail-" + e.data.id} onClick={() => editSale(e)} />
                                        {
                                            permissionsModule.find(v => { return v.functionName === 'Cancel_sale' }) ?
                                                (
                                                    <i style={{ cursor: 'pointer', marginLeft: '10px' }} id={"Tooltip-cancel-" + e.data.id} className="fa fa-times fa-2x" onClick={() => cancelSale(e.data)} />

                                                ) :
                                                ''
                                        }
                                        <TooltipHtmlElementItem key={e.data.id} item={e.data} />
                                    </div>
                                </td>
                                <td>{e.data.customerName}</td>
                                <td>{e.data.total}</td>
                                <td>{moment(e.data.createDate).format("DD/MM/YYYY")}</td>
                                <td>{e.data.userName}</td>
                            </tr>
                        )

                }

            </>);
    }

    return (
        <Fragment>
            <Breadcrumb parent={t('sales')} title={t("titleSales")} />
            <Container fluid={true}>
                <Row className="justify-content-md-center">
                    <Col sm="12">
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col md="4 mb-3">
                                        <Label>{t("beginingDate")}</Label>
                                        <DateBox className="form-control" displayFormat={"dd/MM/yyyy"} onValueChanged={ev => setBeginingDate(ev.value)}
                                            type="date" />
                                    </Col>
                                    <Col md="4 mb-3">
                                        <Label>{t("endingDate")}</Label>
                                        <DateBox className="form-control" displayFormat={"dd/MM/yyyy"} onValueChanged={ev => setEndingDate(ev.value)}
                                            type="date" />
                                    </Col>
                                    <Col md="4 mb-3">
                                        <div className="btn-showcase " style={{ marginTop: '35px' }}>
                                            <Button className="btn-pill" color="primary" onClick={getSalesByDate}><i className="fa fa-search"></i>{tab + tab}{t('search')}</Button>
                                        </div>

                                    </Col>
                                </Row>
                                <Row >
                                    {/* Popup */}
                                    <PopupDeatilSale
                                        controlModal={controlModal} setControlModal={setControlModal}
                                        saleData={saleData} setSaleData={setSaleData}
                                    />
                                    <Col sm="12" lg="12" xl="12">
                                        <div className="table-responsive">
                                            <div id="data-grid-demo" className="table-primary">
                                                <div className="btn-showcase ">
                                                    <Button className="btn-pill" color="primary" onClick={createSale}><i className="icofont icofont-ui-add"></i>{tab + tab}{t('create')}</Button>
                                                </div>

                                                <DataGrid
                                                    dataSource={listSales}
                                                    keyExpr="id"
                                                    showBorders={true}
                                                    rowAlternationEnabled={true}
                                                    columnAutoWidth={true}
                                                    t={t}
                                                    dataRowRender={rowRenderMethod}
                                                >
                                                    <HeaderFilter visible={true} allowSearch={true} />
                                                    <Export enabled={true} />
                                                    <SearchPanel visible={true} highlightCaseSensitive={true} width={350} />
                                                    <Scrolling
                                                        useNative={false}
                                                        scrollByContent={true}
                                                        scrollByThumb={true}
                                                        showScrollbar="onHover" />
                                                    <Paging defaultPageSize={5} />
                                                    <Pager
                                                        showPageSizeSelector={true}
                                                    />

                                                    <Editing
                                                        mode="popup"
                                                        allowUpdating={false}
                                                        allowAdding={false}
                                                        allowDeleting={false}>
                                                    </Editing>

                                                    <Column caption={t('actions')} cellRender={cellRenderAction} />
                                                    <Column dataField="customerName" caption={t("client")} alignment={"center"} >
                                                    </Column>
                                                    <Column dataField="total" caption={t('total')} dataType="number" format="currency" alignment={"center"}>
                                                        <RequiredRule />
                                                    </Column>
                                                    <Column dataField="createDate" caption={t('date')} dataType={"date"} format={"dd/MM/yyyy"} alignment={"center"} >
                                                        <RequiredRule />
                                                    </Column>
                                                    <Column dataField="userName" caption={t('seller')} alignment={"center"}>
                                                        <RequiredRule />
                                                    </Column>
                                                    <Summary>
                                                        <TotalItem
                                                            column="total"
                                                            summaryType="sum"
                                                            valueFormat="currency" />
                                                    </Summary>

                                                </DataGrid>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <div className={loading ? 'loader-wrapper back' : 'loader-wrapper back loderhide'}><div className="loader-index">
                                    <span></span></div>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );

};

export default SalesList;
