import React, { Fragment, useState, useEffect } from "react";

import { DataGrid, Column, Lookup, MasterDetail } from 'devextreme-react/data-grid';
import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';
import axios from "axios";
import { useTranslation } from 'react-i18next';
import { Container, Row, Col, Card, CardHeader, Table, CardBody, Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import PopupCatalogT from "./popupCatalogT";
import DetailTemplateFoCat from './detailTemplateFoCat';


const DetailTemplateThirdCat = (props) => {

    console.log(props);
    // Spaces
    const tab = '\u00A0';

    // Get the information of user
    const infoUserLogin = JSON.parse(localStorage.getItem('infoUserLogin'));

    const [catalogThirdL, setCatalogThirdL] = useState('');
    const { accountName, accountNumber } = props.data.data;

    // To traslate the words
    const { t } = useTranslation();

    // To get the information of the first catalog
    const [dataCatalogThird, setDataCatalogThird] = useState([]);

    const [readOnlyForm, setReadOnlyForm] = useState(false);

    // Id of the first level of catalog
    const [idCatSecondLevel, setIdCatSecondLevel] = useState('');



    // To get the list information of the menu
    const [catalogListThird, setCatalogListThird] = useState([]);

    // To get the list of status
    const [listStatus, setListStatus] = useState([]);

    // To determinate if the event is create or edit:  edit:true and create:false
    const [isEdit, setIsEdit] = useState(false);

    const [controlModal, setControlModal] = useState(false);

    // To get the status of Menu
    const [statusCatalogT, setStatusCatalogT] = useState('');

    // Is editable catalog account
    const [isEditableA, setIsEditableA] = useState(2);

    // Id Classification account CDSP, DSP
    const [idClassificationT, setIdClassificationT] = useState('');

    // Array  classification
    var classificationAccountT = [{
        'id': 1,
        'descrip': "CDSP",
    }, {
        'id': 2,
        'descrip': "CSP",
    },
    {
        'id': 3,
        'descrip': "Ninguno",
    },
    ];

    const arrayIsEditableA = [
        { id: 1, text: t('positiveAnswer') },
        { id: 2, text: t('negativeAnswer') },
    ];

    const [dataClassificationT, setDataClassificationT] = useState(classificationAccountT);


    // Get the list of second catalog
    useEffect(() => {
        // getThirdCatalog();
        // We pass like parameter 1 because 1 has the general status
        axios
            .get(`${process.env.REACT_APP_DOMAIN_SERVER}api/processState/${1}`)
            .then((response) => {
                setListStatus(response.data.listStatus);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);


    // Use effect is launch one time when the page load
    useEffect(() => {

        if (infoUserLogin !== null && infoUserLogin !== undefined) {
            setIdCatSecondLevel(props.data.data.IdCatSecondLevel);

            getThirdCatalog();

            // To determinate if the user logged is Admin
            setReadOnlyForm(infoUserLogin.idRol == 1 ? false : true);
        }

    }, []);


    // To create edit icon 
    const cellRenderAction = (data) => {
        return <div align="center"><i style={{ cursor: 'pointer' }} className="icofont icofont-ui-edit" onClick={() => editThirdCatalog(data)} /></div>;
    }

    const editThirdCatalog = (e) => {
        setIsEdit(true);
        setDataCatalogThird(e.data);
        setControlModal(!controlModal);
        setStatusCatalogT(e.data.statusAccountT);
        setIdClassificationT(e.data.IdClassification != null ? e.data.IdClassification : '');
        setIsEditableA(e.data.isEditable != null ? e.data.isEditable : 2);

    }

    const createThirdAccount = (e) => {
        setDataCatalogThird({
            accountNumber: accountNumber,
            accountName: "",
        });
        setIsEdit(false);
        setControlModal(!controlModal);
        setIdClassificationT(1);
        setIsEditableA(2);

    };

    return (
        <React.Fragment>
            <div className="master-detail-caption">

                <div className="btn-showcase ">
                    <Row>
                        <Col md="1 mb-3" >
                            <Button className="btn-pill" color="primary" onClick={createThirdAccount}><i className="icofont icofont-ui-add"></i></Button>

                        </Col>
                        <Col md="2 mb-3">
                            <h5>{`${accountName}`}</h5>

                        </Col>

                    </Row>

                </div>
            </div>
            <PopupCatalogT
                controlModal={controlModal} setControlModal={setControlModal}
                dataCatalogThird={dataCatalogThird}
                isEdit={isEdit}
                listStatus={listStatus}
                setCatalogListThird={setCatalogListThird}
                statusCatalogT={statusCatalogT}
                setStatusCatalogT={setStatusCatalogT}
                readOnlyForm={readOnlyForm}
                accountName={accountName}
                idCatSecondLevel={idCatSecondLevel}
                setCatalogThirdL={setCatalogThirdL}
                idClassification={idClassificationT}
                setIdClassification={setIdClassificationT}
                dataClassification={dataClassificationT}
                isEditableA={isEditableA}
                setIsEditableA={setIsEditableA}

            />


            <DataGrid
                dataSource={getInfoThirdCatalog(props.data.key)}
                showBorders={true}
                columnAutoWidth={true}
                // keyExpr="IdCatSecondLevel"
                rowAlternationEnabled={true}
                t={t}
            >
                <Column caption={t('actions')} cellRender={cellRenderAction} width={100} />
                <Column dataField="accountNumber" />
                <Column dataField="accountName" />
                <Column dataField="IdClassification" caption={t('classificationAccount')} >
                    <Lookup dataSource={classificationAccountT} valueExpr="id" displayExpr="descrip" />
                </Column>
                <Column dataField="isEditable" caption={t('isEditableA')} >
                    <Lookup dataSource={arrayIsEditableA} valueExpr="id" displayExpr="text" />
                </Column>
                <Column dataField="statusAccountT" caption={t("selectStatus")} >
                    <Lookup dataSource={listStatus} valueExpr="id" displayExpr="name" />
                </Column>
                <MasterDetail
                    enabled={true}
                    component={DetailTemplateFoCat}
                />

            </DataGrid>
        </React.Fragment>
    );



    // Function that allow get the third catalogs
    function getThirdCatalog() {
        axios
            .get(`${process.env.REACT_APP_DOMAIN_SERVER}api/getThirdCatalogsByIdR/${infoUserLogin.idRol}`)
            .then((response) => {
                setCatalogThirdL(response.data.thirdCatalog);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    // Function that allow filtering information from second catalogs
    function getInfoThirdCatalog(key) {
        return new DataSource({
            store: new ArrayStore({
                data: catalogThirdL,
                key: 'IdCatThirdLevel',
            }),
            filter: ['IdCatSecondLevel', '=', key],
        });

    }
}

export default DetailTemplateThirdCat;
