import React, { useState, Fragment, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form'
import { Container, Row, Col, Form, Label, Input, Card, FormGroup, InputGroup, InputGroupText, CardHeader, Table, CardBody, Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import axios from "axios";
import { SelectBox } from 'devextreme-react/select-box';
import swal from 'sweetalert';
import RadioGroup from 'devextreme-react/radio-group';


export default function PopupCatalogSix(
    {
        controlModal, setControlModal,
        dataCatalogSixth,
        isEdit,
        listStatus,
        statusCatalogSixth,
        setStatusCatalogSixth,
        idCatFifthLevel,
        setCatalogSixthL,
        accountName,
        idClassification,
        setIdClassification,
        dataClassification,
        isEditableA,
        setIsEditableA,
    }
) {

    const [loading, setLoading] = useState(false);

    // Get the information of the logged user
    const infoUserLogin = JSON.parse(localStorage.getItem('infoUserLogin'));

    // Class allow validate
    const { register, reset, handleSubmit, formState: { errors }, control } = useForm();

    const [validateClass, setValidateClass] = useState(false);

    //for errors setup
    const [error, setError] = useState({});

    // User translation
    const { t } = useTranslation();

    const arrayIsEditableA = [
        { id: 1, text: t('positiveAnswer') },
        { id: 2, text: t('negativeAnswer') },
    ];


    const onSubmit: SubmitHandler<FormValues> = data => {
        // If all validations are met we'll call register method
        if (isEdit) {
            updateCatalogSixth(data);
        } else {

            // Validate that the account number isn't duplicated
            if (infoUserLogin !== null && infoUserLogin !== undefined) {

                if (data.accountNumber !== "" && data.accountNumber !== null) {

                    // Check if the number account already exist

                    let levelC = 6; // Indacates validation to the first level

                    let datos = {
                        levelC: levelC,
                        accountN: data.accountNumber,
                        IdChurch: infoUserLogin.IdChurch
                    }

                    // Conver to string the object

                    datos = JSON.stringify(datos);

                    axios
                        .get(`${process.env.REACT_APP_DOMAIN_SERVER}api/existAccountNumb/${datos}`)
                        .then((response) => {
                            if (response.data.exists == true) {
                                swal("¡Atención!", t('errorAccountNumberDup'), "warning");
                            } else {
                                // If everything it is fine
                                createCatalogueFifth(data);

                            }
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                }
            }


        }
    }

    useEffect(() => {
        setValidateClass(false);
        reset({
            accountNumber: dataCatalogSixth.accountNumber,
            accountName: dataCatalogSixth.accountName,
        });
    }, [controlModal])

    const handleStatusCatalogSixth = (newvalue) => {
        if (newvalue.value !== null && newvalue.value !== undefined) {
            // Set the id
            setStatusCatalogSixth(newvalue.value.id);
        } else {
            // Clear the information of Status menu
            setStatusCatalogSixth('');
        }
    }


    const changeStatusModalRol = () => {
        setControlModal(!controlModal)
    };

    // Function that allow save a new record
    const createCatalogueFifth = (data) => {
        if (infoUserLogin.id !== null && infoUserLogin.id !== '' && idClassification !== '') {
            //validation empy fields
            setLoading(true);

            const infoCreate = {
                accountName: data.accountName,
                accountNumber: data.accountNumber,
                whoCreated: infoUserLogin.id,
                IdCatFifthLevel: idCatFifthLevel,
                IdClassification: idClassification,
                isEditable: isEditableA,
            };

            axios.post(`${process.env.REACT_APP_DOMAIN_SERVER}api/saveCatSixth`, infoCreate)
                .then((response) => {
                    setValidateClass(false);
                    setLoading(false);
                    toast.info(t('successCreated'));
                    // Load the list of information
                    loadCatalogSixth();
                })
                .catch((errors) => {
                    setError(errors.response.data.messages)
                    console.log(errors);
                });
        } else {
            setTimeout(() => {
                toast.error(t('errorLogin'));
            }, 200);
        }
    };

    // Function that allow update the record
    const updateCatalogSixth = (data) => {
        if (infoUserLogin.id !== null && infoUserLogin.id !== '' && dataCatalogSixth.IdCatSixthLevel !== undefined) {
            //validation empy fields
            if (statusCatalogSixth !== undefined && statusCatalogSixth !== '' && idClassification !== '') {
                setLoading(true);

                const infoUpdate = {
                    accountNumber: data.accountNumber,
                    accountName: data.accountName,
                    statusAccount: statusCatalogSixth,
                    whodidit: infoUserLogin.id,
                    IdClassification: idClassification,
                    isEditable: isEditableA,
                };
                axios.put(`${process.env.REACT_APP_DOMAIN_SERVER}api/updateSixthCat/${dataCatalogSixth.IdCatSixthLevel}`, infoUpdate)
                    .then((response) => {
                        setValidateClass(false);
                        setLoading(false);
                        toast.info(t('successUpdated'));
                        loadCatalogSixth();
                    })
                    .catch((errors) => {
                        setError(errors.response.data.messages)
                    });
            } else {
                toast.error(t('completeFields'));

            }
        } else {
            setTimeout(() => {
                toast.error(t('errorLogin'));
            }, 200);
        }

    };


    // Function that allow get the second catalogs
    function loadCatalogSixth() {
        axios
            .get(`${process.env.REACT_APP_DOMAIN_SERVER}api/getSixthCatalogsByIdR/${infoUserLogin.IdChurch}`)
            .then((response) => {
                setCatalogSixthL(response.data.sixthCatalog);
                setControlModal(!controlModal);
            })
            .catch((error) => {
                console.log(error);
            });

    }

    // Handle classification
    const handleClasification = (newvalue) => {

        if (newvalue.value !== null && newvalue.value !== undefined) {
            // Set the id
            setIdClassification(newvalue.value.id);

        } else {
            // Clear the information of Id 
            setIdClassification('');
        }
    }

    const onBlurAccountN = (accountN) => {

        if (infoUserLogin !== null && infoUserLogin !== undefined) {

            if (accountN !== "" && accountN !== null) {

                // Check if the number account already exist

                let levelC = 6; // Indacates validation to the first level

                let datos = {
                    levelC: levelC,
                    accountN: accountN,
                    IdChurch: infoUserLogin.IdChurch
                }
                // Conver to string the object

                datos = JSON.stringify(datos);

                axios
                    .get(`${process.env.REACT_APP_DOMAIN_SERVER}api/existAccountNumb/${datos}`)
                    .then((response) => {
                        if (response.data.exists == true) {
                            swal("¡Atención!", t('errorAccountNumberDup'), "warning");
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }

        }

    }


    const changeSelectionRb = (event) => {
        setIsEditableA(event.value);
    }

    return (
        <Fragment>
            <Modal
                size="lg" isOpen={controlModal} centered>
                <Form className={`needs-validation tooltip-validation ${validateClass ? 'validateClass' : ''}`} noValidate="" onSubmit={handleSubmit(onSubmit)}>

                    <ModalHeader toggle={changeStatusModalRol}>
                        {isEdit ? (t("editInfo") + '   ' + `${accountName}`) : (t("createInfo") + '   ' + `${accountName}`)}
                    </ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col md="6 mb-2">
                                <Label>{t("accountNumber")}</Label>
                                <input className="form-control btn-pill" type="text" defaultValue={dataCatalogSixth.accountNumber} placeholder={t("accountNumber")} {...register('accountNumber', { required: true, onBlur: (e) => onBlurAccountN(e.target.value) })} />
                                <span>{errors.accountNumber && t("errorRequired")}</span>
                            </Col>
                            <Col md="6 mb-2">
                                <Label>{t("accountName")}</Label>
                                <input className="form-control btn-pill" type="text" defaultValue={dataCatalogSixth.accountName} placeholder={t("accountName")} {...register('accountName', { required: true })} />
                                <span>{errors.accountName && t("errorRequired")}</span>
                            </Col>

                            <Col md="6 mb-2">
                                <Label>{t("classificationAccount")}</Label>
                                <SelectBox
                                    dataSource={dataClassification}
                                    displayExpr="descrip"
                                    defaultValue={dataClassification.find(v => v.id == idClassification)}
                                    searchEnabled={true}
                                    className={'form-control dxSelectBorder'}
                                    placeholder={t('classificationAccount')}
                                    showClearButton={true}
                                    name="IdClassification"
                                    onValueChanged={handleClasification}
                                />

                                <input type="hidden" />
                                <span>{((idClassification === '' || idClassification === undefined) && validateClass) && t("errorRequired")}</span>
                            </Col>

                            <Col md="6 mb-2">
                                <Label>{t("isEditableA")}</Label>
                                <RadioGroup
                                    id="radio-group-with-selection"
                                    items={arrayIsEditableA}
                                    defaultValue={arrayIsEditableA.find(v => v.id == isEditableA).id}
                                    valueExpr="id"
                                    displayExpr="text"
                                    layout="horizontal"
                                    onValueChanged={changeSelectionRb}
                                />
                            </Col>

                            {isEdit ?
                                <Col md="6 mb-2">
                                    <Label>{t("selectStatus")}</Label>
                                    <SelectBox
                                        dataSource={listStatus}
                                        displayExpr="name"
                                        defaultValue={listStatus.find(v => v.id === statusCatalogSixth)}
                                        searchEnabled={true}
                                        className={'form-control dxSelectBorder'}
                                        placeholder={t('selectStatus')}
                                        showClearButton={true}
                                        name="selectStatus"
                                        onValueChanged={handleStatusCatalogSixth}
                                    />
                                    <input type="hidden" />
                                    <span>{((statusCatalogSixth === '' || statusCatalogSixth === undefined) && validateClass) && t("errorRequired")}</span>
                                </Col>
                                : ''
                            }

                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={changeStatusModalRol} >{t('close')}</Button>
                        <Button color="primary" type="submit" disabled={loading ? loading : loading} onClick={() => setValidateClass(true)} >{loading ? t("processing") : isEdit ? t('update') : t('create')}</Button>
                    </ModalFooter>
                </Form>

                <div className={loading ? 'loader-wrapper back' : 'loader-wrapper back loderhide'}><div className="loader-index">
                    <span></span></div>
                </div>

            </Modal>
        </Fragment>
    );
}
