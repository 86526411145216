import React, { Fragment, useState, useEffect } from "react";
import Breadcrumb from "../../../layout/breadcrumb";
import { Container, Row, Col, Card, CardHeader, Table, CardBody, Button, Modal, ModalHeader, ModalBody, ModalFooter, Tooltip } from "reactstrap";
import axios from "axios";
import 'devextreme/dist/css/dx.material.teal.light.css';

import DataGrid, { Column, Editing, Popup, Paging, Lookup, Form, SearchPanel, Scrolling, Pager, Export, HeaderFilter, RequiredRule, MasterDetail } from 'devextreme-react/data-grid';
import 'devextreme-react/text-area';
import { useTranslation } from 'react-i18next';
import DetailTemplatePetty from './detailTemplatePetty';


const ReplenishmentListHistory = () => {

  // Get the information of user

  const infoUserLogin = JSON.parse(localStorage.getItem('infoUserLogin'));

  // To traslate the words
  const { t } = useTranslation();

  // Spaces
  const tab = '\u00A0';

  // To get the information of the sub categories
  const [dataPettyCashActive, setDataPettyCashActive] = useState([]);

  // To get the information of the list petty history
  const [listPettyH, setListPettyH] = useState([]);

  // To determinate if the event is create or edit:  edit:true and create:false
  const [isEdit, setIsEdit] = useState(false);

  const [controlModal, setControlModal] = useState(false);

  // To save the account Name
  const [accountName, setAccountName] = useState('');
  // To save the movement type
  const [movementType, setMovementType] = useState(1);


  const [loading, setLoading] = useState(false);


  // Use effect is launch one time when the page load
  useEffect(() => {
    loadDefaultFc();
  }, []);


  function loadDefaultFc() {
    if (infoUserLogin !== null && infoUserLogin !== undefined) {

      axios
        .get(`${process.env.REACT_APP_DOMAIN_SERVER}api/getPetyCashH/${infoUserLogin.IdChurch}`)
        .then((response) => {
          setListPettyH(response.data.pettyCashHistory);
        })
        .catch((error) => {
          console.log(error);
        });


    }
  }


  // Function that allow create an toltip element
  const TooltipHtmlElementItem = props => {
    const { item } = props;
    const [tooltip, setTooltip] = useState(false)
    const HtmlelElementToggle = () => setTooltip(!tooltip);


    const [tooltipDelete, setTooltipDelete] = useState(false)
    const HtmlelElementToggleDelete = () => setTooltipDelete(!tooltipDelete);
    return (
      <>
        <Tooltip
          placement={"top"}
          isOpen={tooltip}
          target={"Tooltip-seeReport-" + item.IdPettyCash}
          toggle={HtmlelElementToggle}
        >
          {t("seeReport")}
        </Tooltip>
        <Tooltip
          placement={"top"}
          isOpen={tooltipDelete}
          target={"Tooltip-seeCompleteReport-" + item.IdPettyCash}
          toggle={HtmlelElementToggleDelete}
        >
          {t("seeCompleteReport")}
        </Tooltip>
       </>
     );
  };


  const cellRenderAction = (data) => {
    return <div align="center">
      <i style={{ cursor: 'pointer' }} className="fa fa-eye fa-2x"  id={"Tooltip-seeReport-" + data.data.IdPettyCash} onClick={() => getReportRequest(data)} />
      <i style={{ cursor: 'pointer', marginLeft: '10px' }} id={"Tooltip-seeCompleteReport-" + data.data.IdPettyCash} className="fa fa-file-archive-o fa-2x" onClick={() => reportComplete(data)} />
      <TooltipHtmlElementItem key={data.data.IdPettyCash} item={data.data} />
    </div>;
  }

  const createReplenishment = (e) => {
    setDataPettyCashActive(
      {
        dateDetailPettyCash: new Date(),
        documentNumber: "",
        accountNumber: "",
        descriptionDetail: "",
        amount: "",
        IdPettyCash: 1,
      }
    );
    setAccountName('');
    setMovementType(2);
    setIsEdit(false);
    setControlModal(!controlModal);
  };

  const getReportRequest = (e) => {
    if (e.data != null && e.data != undefined && infoUserLogin !== null && infoUserLogin !== undefined) {
      window.open(`${process.env.REACT_APP_DOMAIN_SERVER}api/getReportRr/${infoUserLogin.IdChurch}/${e.data.IdPettyCash}`, '_blank');
    }
  }

  const reportComplete = (e) => {
    if (e.data != null && e.data != undefined && infoUserLogin !== null && infoUserLogin !== undefined) {
      window.open(`${process.env.REACT_APP_DOMAIN_SERVER}api/getReportRrCp/${infoUserLogin.IdChurch}/${e.data.IdPettyCash}`, '_blank');
    }
  }

  return (
    <Fragment>
      <Breadcrumb parent="Caja Chica" title={t("replenishmenment")} />
      <Container fluid={true}>
        <Row className="justify-content-md-center">
          <Col sm="12">
            <Card>
              <CardBody>
                <Row >
                  <Col sm="12" lg="12" xl="12">
                    <div className="table-responsive">
                      <div id="data-grid-demo" className="table-primary">



                        <DataGrid
                          dataSource={listPettyH}
                          keyExpr="IdPettyCash"
                          showBorders={true}
                          rowAlternationEnabled={true}
                          columnAutoWidth={true}
                          t={t}
                        >
                          <HeaderFilter visible={true} allowSearch={true} />
                          <Export enabled={true} />
                          <SearchPanel visible={true} highlightCaseSensitive={true} width={350} />
                          <Scrolling
                            useNative={false}
                            scrollByContent={true}
                            scrollByThumb={true}
                            showScrollbar="onHover" />
                          <Paging defaultPageSize={10} />
                          <Pager
                            showPageSizeSelector={true}
                          />

                          <Editing
                            mode="popup"
                            allowUpdating={false}
                            allowAdding={false}
                            allowDeleting={false}>
                          </Editing>

                          <Column caption={t('actions')} cellRender={cellRenderAction} width={100} />
                          <Column dataField="datePettyCash" caption={t('beginingDate')} >
                            <RequiredRule />
                          </Column>
                          <Column dataField="statusName" caption={t('selectStatus')} >
                            <RequiredRule />
                          </Column>
                          <Column dataField="amountPettyCash" caption={t('amountO')} >
                            <RequiredRule />
                          </Column>

                          <MasterDetail
                            enabled={true}
                            component={DetailTemplatePetty}
                          />

                        </DataGrid>
                      </div>

                      <div className={loading ? 'loader-wrapper back' : 'loader-wrapper back loderhide'}><div className="loader-index">
                        <span></span></div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );

};

export default ReplenishmentListHistory;
