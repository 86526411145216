import React, { Fragment } from 'react';
import Breadcrumb from '../../layout/breadcrumb'
import { VerticalScrollbar, HorizontalScrollbar, SmoothScrollbar, NoScrollbar } from '../../constant'
import ScrollBar from "react-perfect-scrollbar";
import { Container, Row, Col, Card, CardHeader, CardBody } from 'reactstrap'

const ExchangeHands = () => {

  return (

    <Row>
      <Col sm="12">
        <Card>

          <CardBody>
           
              <div>
                <img src={require("../../assets/images/welcomePage/exchangeHands.jpg")} alt="" style={{ width: '100%', height: "100%", opacity:0.3 }} />

              </div>
          </CardBody>
        </Card>
      </Col>
    </Row>

  );
};

export default ExchangeHands;