import React, { Fragment, useState, useEffect } from "react";

import { DataGrid, Column, Lookup, MasterDetail } from 'devextreme-react/data-grid';
import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';
import axios from "axios";
import { useTranslation } from 'react-i18next';
import { Container, Row, Col, Card, CardHeader, Table, CardBody, Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import PopupCatalogFo from "./popupCatalogFo";

import DetailTemplateFifthCat from './detailTemplateFifthCat';

const DetailTemplateFoCat = (props) => {

    // Spaces
    const tab = '\u00A0';

    // Get the information of user
    const infoUserLogin = JSON.parse(localStorage.getItem('infoUserLogin'));

    const [catalogFourthL, setCatalogFourthL] = useState('');
    const { accountName, accountNumber } = props.data.data;

    // To traslate the words
    const { t } = useTranslation();

    // To get the information of the first catalog
    const [dataCatalogFourth, setDataCatalogFourth] = useState([]);

    const [readOnlyForm, setReadOnlyForm] = useState(false);

    // Id of the first level of catalog
    const [idCatThirdLevel, setIdCatThirdLevel] = useState('');



    // To get the list information of the menu
    const [catalogListFourth, setCatalogListFourth] = useState([]);

    // To get the list of status
    const [listStatus, setListStatus] = useState([]);

    // To determinate if the event is create or edit:  edit:true and create:false
    const [isEdit, setIsEdit] = useState(false);

    const [controlModal, setControlModal] = useState(false);

    // To get the status of Menu
    const [statusCatalogFo, setStatusCatalogFo] = useState('');

    // Id Classification account CDSP, DSP
    const [idClassificationFo, setIdClassificationFo] = useState('');

    // Is editable catalog account
    const [isEditableA, setIsEditableA] = useState(2);

    // Array  classification
    var classificationAccountFo = [{
        'id': 1,
        'descrip': "CDSP",
    }, {
        'id': 2,
        'descrip': "CSP",
    },
    {
        'id': 3,
        'descrip': "Ninguno",
    },
    ];

    const arrayIsEditableA = [
        { id: 1, text: t('positiveAnswer') },
        { id: 2, text: t('negativeAnswer') },
    ];

    const [dataClassificationFo, setDataClassificationFo] = useState(classificationAccountFo);


    // Get the list of second catalog
    useEffect(() => {
        // getFourthCatalog();
        // We pass like parameter 1 because 1 has the general status
        axios
            .get(`${process.env.REACT_APP_DOMAIN_SERVER}api/processState/${1}`)
            .then((response) => {
                setListStatus(response.data.listStatus);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);


    // Use effect is launch one time when the page load
    useEffect(() => {

        if (infoUserLogin !== null && infoUserLogin !== undefined) {
            setIdCatThirdLevel(props.data.data.IdCatThirdLevel);

            getFourthCatalog();

            // To determinate if the user logged is Admin
            setReadOnlyForm(infoUserLogin.idRol == 1 ? false : true);
        }

    }, []);


    // To create edit icon 
    const cellRenderAction = (data) => {
        return <div align="center"><i style={{ cursor: 'pointer' }} className="icofont icofont-ui-edit" onClick={() => editFourthCatalog(data)} /></div>;
    }

    const editFourthCatalog = (e) => {
        setIsEdit(true);
        setDataCatalogFourth(e.data);
        setControlModal(!controlModal);
        setStatusCatalogFo(e.data.statusAccountFo);
        setIdClassificationFo(e.data.IdClassification != null ? e.data.IdClassification : '');
        setIsEditableA(e.data.isEditable != null ? e.data.isEditable : 2);

    }

    const createFourthAccount = (e) => {
        setDataCatalogFourth({
            accountNumber: accountNumber,
            accountName: "",
        });
        setIsEdit(false);
        setControlModal(!controlModal);
        setIdClassificationFo(1);
        setIsEditableA(2);


    };

    return (
        <React.Fragment>
            <div className="master-detail-caption">

                <div className="btn-showcase ">
                    <Row>
                        <Col md="1 mb-3" >
                            <Button className="btn-pill" color="primary" onClick={createFourthAccount}><i className="icofont icofont-ui-add"></i></Button>

                        </Col>
                        <Col md="2 mb-3">
                            <h5>{`${accountName}`}</h5>

                        </Col>

                    </Row>

                </div>
            </div>
            <PopupCatalogFo
                controlModal={controlModal} setControlModal={setControlModal}
                dataCatalogFourth={dataCatalogFourth}
                isEdit={isEdit}
                listStatus={listStatus}
                setCatalogListFourth={setCatalogListFourth}
                statusCatalogFo={statusCatalogFo}
                setStatusCatalogFo={setStatusCatalogFo}
                readOnlyForm={readOnlyForm}
                accountName={accountName}
                idCatThirdLevel={idCatThirdLevel}
                setCatalogFourthL={setCatalogFourthL}
                idClassification={idClassificationFo}
                setIdClassification={setIdClassificationFo}
                dataClassification={dataClassificationFo}
                isEditableA={isEditableA}
                setIsEditableA={setIsEditableA}
            />


            <DataGrid
                dataSource={getInfoFourthCatalog(props.data.key)}
                showBorders={true}
                columnAutoWidth={true}
                // keyExpr="IdCatThirdLevel"
                rowAlternationEnabled={true}
                t={t}
            >
                <Column caption={t('actions')} cellRender={cellRenderAction} width={100} />
                <Column dataField="accountNumber" />
                <Column dataField="accountName" />
                <Column dataField="IdClassification" caption={t('classificationAccount')} >
                    <Lookup dataSource={classificationAccountFo} valueExpr="id" displayExpr="descrip" />
                </Column>
                <Column dataField="isEditable" caption={t('isEditableA')} >
                    <Lookup dataSource={arrayIsEditableA} valueExpr="id" displayExpr="text" />
                </Column>
                <Column dataField="statusAccountFo" caption={t("selectStatus")} >
                    <Lookup dataSource={listStatus} valueExpr="id" displayExpr="name" />
                </Column>
                <MasterDetail
                    enabled={true}
                    component={DetailTemplateFifthCat}
                />

            </DataGrid>
        </React.Fragment>
    );



    // Function that allow get the fourth catalogs
    function getFourthCatalog() {
        axios
            .get(`${process.env.REACT_APP_DOMAIN_SERVER}api/getFourthCatalogsByIdR/${infoUserLogin.idRol}`)
            .then((response) => {
                setCatalogFourthL(response.data.fourthCatalog);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    // Function that allow filtering information from fourth catalogs
    function getInfoFourthCatalog(key) {
        return new DataSource({
            store: new ArrayStore({
                data: catalogFourthL,
                key: 'IdCatFourthLevel',
            }),
            filter: ['IdCatThirdLevel', '=', key],
        });
    }
}

export default DetailTemplateFoCat;
