import React, { Fragment, useState, useEffect } from "react";
import Breadcrumb from "../../layout/breadcrumb";
import { Container, Row, Col, Card, CardBody, Button, Tooltip } from "reactstrap";
import axios from "axios";
import { classes } from "../../data/layouts";
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import 'devextreme/dist/css/dx.material.teal.light.css';
import DataGrid, {
  Column, Editing, Paging, Lookup, SearchPanel, Scrolling, Pager, Export, HeaderFilter, RequiredRule
} from 'devextreme-react/data-grid';
import 'devextreme-react/text-area';
import PopupEditUser from "./popupEditUser";
import PopupChangePass from "./popupChangePass";


const UserList = () => {

  const infoUserLogin = JSON.parse(localStorage.getItem('infoUserLogin'));

  //variables estandar
  const { t } = useTranslation();
  const navigate = useNavigate();
  const defaultLayoutObj = classes.find(
    (item) => Object.values(item).pop(1) === "compact-wrapper"
  );
  const layout = localStorage.getItem("layout") || Object.keys(defaultLayoutObj).pop();

  //variables para la lista de usuarios
  const [usersList, setUsersList] = useState([]);
  const [employeesList, setEmployeesList] = useState([]);
  const [statusList, setStatusList] = useState([]);
  const tab = '\u00A0';

  const [permissionsModule, setPermissionsModule] = useState([]);


  //variables para la modal
  const [openPopup, setOpenPopup] = useState(false);
  const [dataUser, setDataUser] = useState([]);
  const [idUser, setIdUser] = useState('');
  const [userName, setUserName] = useState('');
  const [email, setEmail] = useState('');
  const [statusUser, setStatusUser] = useState('');
  const [idEmployee, setIdEmployee] = useState('');
  const [error, setError] = useState({
    'userName': '',
    'email': '',
    'idEmployee': ''
  });
  const [keyUserName, setKeyUserName] = useState('')

  // Modal Change password
  const [openPopupChangeP, setOpenPopupChangeP] = useState(false);



  useEffect(() => {
    if (infoUserLogin !== null && infoUserLogin !== undefined) {
      axios
        .get(`${process.env.REACT_APP_DOMAIN_SERVER}api/getInfoUserByRol/${infoUserLogin.idRol}`)
        .then((res) => {
          setUsersList(res.data.users);
          setStatusList(res.data.status);
        })
        .catch((err) => {
          console.log(err);
        });

      // Get employees by IdRol
      axios
        .get(`${process.env.REACT_APP_DOMAIN_SERVER}api/getInfoEmpByRol/${infoUserLogin.idRol}`)
        .then((res) => {
          setEmployeesList(res.data.employees);
        })
        .catch((err) => {
          console.log(err);
        });

      // Get general permissions

      if (infoUserLogin !== null) {
        if (infoUserLogin.id !== null && infoUserLogin.id !== '') {
          axios
            .get(`${process.env.REACT_APP_DOMAIN_SERVER}/api/getPermission/${infoUserLogin.idRol}`)
            .then((result) => {
              setPermissionsModule(result.data.response);
            })
            .catch((error) => {
              console.log(error);
            });

        } else {
          setPermissionsModule([]);
        }
      } else {
        setPermissionsModule([]);

      }
    }
  }, []);

  //redirección al formulario de creación
  const createUser = (e) => {
    navigate(`${process.env.PUBLIC_URL}/app/users/userCreate/${layout}`);
  };

  //funcion que renderiza el botón de edición en la tabla
  // const cellRenderAction = (data) => {
  //   return <div align="center"><i style={{ cursor: 'pointer' }} className="icofont icofont-ui-edit" onClick={() => editUser(data)} /></div>;
  // }

  const cellRenderAction = (data) => {
    return <div align="center">
      <i style={{ cursor: 'pointer' }} className="fa fa-edit fa-2x" id={"Tooltip-editUser-" + data.data.id} onClick={() => editUser(data)} />
      {
        permissionsModule.find(v => { return v.functionName === 'changePassword' }) ?
          (
            <i style={{ cursor: 'pointer', marginLeft: '10px' }} id={"Tooltip-changePassword-" + data.data.id} className="fa fa-unlock fa-2x" onClick={() => changePassword(data.data)} />

          ) :
          ''
      }
      <TooltipHtmlElementItem key={data.data.id} item={data.data} />
    </div>;
  }

  // Function that allow create an toltip element
  const TooltipHtmlElementItem = props => {
    const { item } = props;
    const [tooltip, setTooltip] = useState(false)
    const HtmlelElementToggle = () => setTooltip(!tooltip);


    const [tooltipDelete, setTooltipDelete] = useState(false)
    const HtmlelElementToggleDelete = () => setTooltipDelete(!tooltipDelete);
    return (
      <>
        <Tooltip
          placement={"top"}
          isOpen={tooltip}
          target={"Tooltip-editUser-" + item.id}
          toggle={HtmlelElementToggle}
        >
          {t("edit")}
        </Tooltip>
        {
          permissionsModule.find(v => { return v.functionName === 'changePassword' }) ?
            (
              <Tooltip
                placement={"top"}
                isOpen={tooltipDelete}
                target={"Tooltip-changePassword-" + item.id}
                toggle={HtmlelElementToggleDelete}
              >
                {t("changePassword")}
              </Tooltip>

            ) :
            ''
        }

      </>
    );
  };

  const changePassword = (data) => {
    setDataUser(data);
    setOpenPopupChangeP(true);
  }


  //funcion para levantar la modal de edición de un usuario
  const editUser = (e) => {
    console.log("jere edit");
    setDataUser(e.data);
    setIdUser(e.data.id);
    setUserName(e.data.userName);
    setEmail(e.data.email);
    setIdEmployee(e.data.idEmployee);
    setStatusUser(e.data.status);
    setOpenPopup(true);
    setKeyUserName(Math.random());
  }

  //funcion que devuelve el empleado concatenado para el lookup
  const getEmployeeComplete = (emp) => {
    return emp ? emp.firstName + ' ' + emp.lastName : '';
  }

  return (
    <Fragment>
      <Breadcrumb parent="Users" title={t("listUsers")} />
      <Container fluid={true}>
        <Row >
          <Col sm="12">
            <Card>
              <CardBody>
                <Row >
                  <Col sm="12" lg="12" xl="12">
                    <div className="table-responsive">
                      <div id="data-grid-demo" className="table-primary">
                        <PopupEditUser
                          openPopup={openPopup} setOpenPopup={setOpenPopup}
                          employeesList={employeesList} setEmployeesList={setEmployeesList}
                          idUser={idUser} setIdUser={setIdUser}
                          userName={userName} setUserName={setUserName}
                          email={email} setEmail={setEmail}
                          idEmployee={idEmployee} setIdEmployee={setIdEmployee}
                          statusList={statusList} setStatusList={setStatusList}
                          statusUser={statusUser} setStatusUser={setStatusUser}
                          dataUser={dataUser} setDataUser={setDataUser}
                          setUsersList={setUsersList}
                          error={error} setError={setError}
                          keyUserName={keyUserName} setKeyUserName={setKeyUserName}
                        />

                        <PopupChangePass
                          openPopupChangeP={openPopupChangeP} setOpenPopupChangeP={setOpenPopupChangeP}    
                          employeesList={employeesList} 
                          dataUser={dataUser} setDataUser={setDataUser}
                          error={error} setError={setError}
                          setUsersList={setUsersList}

                        />
                        <div className="btn-showcase ">
                          <Button className="btn-pill" color="primary" onClick={createUser}><i className="icofont icofont-ui-add"></i>{tab + tab}{t('create')}</Button>
                        </div>
                        <DataGrid
                          dataSource={usersList}
                          keyExpr="id"
                          showBorders={true}
                          rowAlternationEnabled={true}
                          columnAutoWidth={true}
                          t={t}
                        >
                          <HeaderFilter visible={true} allowSearch={true} />
                          <Export enabled={true} />
                          <SearchPanel visible={true} highlightCaseSensitive={true} width={450} />
                          <Scrolling
                            useNative={false}
                            scrollByContent={true}
                            scrollByThumb={true}
                            showScrollbar="onHover" />
                          <Paging defaultPageSize={5} />
                          <Pager
                            showPageSizeSelector={true}
                          />
                          <Editing
                            mode="popup"
                            allowUpdating={false}
                            allowAdding={false}
                            allowDeleting={false}>
                          </Editing>
                          <Column caption={t('actions')} cellRender={cellRenderAction} width={100} />
                          <Column dataField="churchName" caption={t('church')} />
                          <Column dataField="userName" caption={t('userName')} >
                            <RequiredRule />
                          </Column>
                          <Column dataField="email" caption={t('email')} />
                          <Column dataField="idEmployee" caption={t('employee')} >
                            <Lookup dataSource={employeesList} valueExpr="id" displayExpr={getEmployeeComplete} />
                          </Column>
                        </DataGrid>
                      </div>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default UserList;
