import React, { Fragment, useState, useEffect } from "react";

import { DataGrid, Column, Lookup, MasterDetail } from 'devextreme-react/data-grid';
import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';
import axios from "axios";
import { useTranslation } from 'react-i18next';
import { Container, Row, Col, Card, CardHeader, Table, CardBody, Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";


const DetailTemplatePetty = (props) => {

    console.log(props);
    // Spaces
    const tab = '\u00A0';

    // Get the information of user
    const infoUserLogin = JSON.parse(localStorage.getItem('infoUserLogin'));

    const [dataDetailPetty, setDataDetailPetty] = useState('');
    const { datePettyCash, setDatePettyCash } = props.data.data;

    // To traslate the words
    const { t } = useTranslation();



    // Use effect is launch one time when the page load
    useEffect(() => {

        if (infoUserLogin !== null && infoUserLogin !== undefined) {
            getDetailPettyCash();
        }

    }, []);


    // To create edit icon 
    // const cellRenderAction = (data) => {
    //     return <div align="center"><i style={{ cursor: 'pointer' }} className="icofont icofont-ui-edit" onClick={() => editThirdCatalog(data)} /></div>;
    // }

    // const editThirdCatalog = (e) => {
    //     setIsEdit(true);
    //     setDataCatalogThird(e.data);
    //     setControlModal(!controlModal);
    //     setStatusCatalogT(e.data.statusAccountT);
    // }

    // const createThirdAccount = (e) => {
    //     setDataCatalogThird({
    //         accountNumber: accountNumber,
    //         accountName: "",
    //     });
    //     setIsEdit(false);
    //     setControlModal(!controlModal);
    // };

    return (
        <React.Fragment>
            <div className="master-detail-caption">

                <div className="btn-showcase ">
                    <Row>
                        <Col md="2 mb-3">
                            <h5>{`${datePettyCash}`}</h5>

                        </Col>
                        <Col md="1 mb-3" >
                            {/* <Button className="btn-pill" color="primary" onClick={createThirdAccount}><i className="icofont icofont-ui-add"></i></Button> */}
                        </Col>
                    </Row>

                </div>
            </div>

            <DataGrid
                dataSource={getInfoDetailPettyCash(props.data.key)}
                showBorders={true}
                columnAutoWidth={true}
                // keyExpr="IdCatSecondLevel"
                rowAlternationEnabled={true}
                t={t}
            >
                <Column dataField="accountNumber" caption={"Código contable Cta., subcta. y su sub cta."} >
                </Column>
                <Column dataField="documentNumber" caption={t('receiptNumber')} >
                </Column>
                <Column dataField="descriptionDetail" caption={t('summaryDescription')} >
                </Column>
                <Column dataField="income" caption={t('income')} >
                </Column>
                <Column dataField="expense" caption={t('expense')} >
                </Column>
                <Column dataField="balance" caption={t('balance')} >
                </Column>
            </DataGrid>
        </React.Fragment>
    );



    // Function that allow get the third catalogs
    function getDetailPettyCash() {
        axios
            .get(`${process.env.REACT_APP_DOMAIN_SERVER}api/getDtPttyC/${infoUserLogin.IdChurch}`)
            .then((response) => {
                setDataDetailPetty(response.data.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    // Function that allow filtering information from second catalogs
    function getInfoDetailPettyCash(key) {
        return new DataSource({
            store: new ArrayStore({
                data: dataDetailPetty,
                key: 'IdDetailPettyCash',
            }),
            filter: ['IdPettyCash', '=', key],
        });

    }
}

export default DetailTemplatePetty;
